import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { alpha, useTheme } from "@mui/material"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import Tooltip from "@mui/material/Tooltip"
import ErrorIcon from "@mui/icons-material/Error"
import fp from "lodash/fp"

import type { AlertSettingStatesById } from "types/alerts.types"
import type { DeviceGroup } from "types/group.types"

import useDevices from "helpers/hooks/useDevices"
import { hasTriggeredAlerts } from "helpers/utils/alerts"
import useRoutes from "helpers/hooks/useRoutes"
import { getColorFromString } from "helpers/utils/styles"
import { Card } from "widgets/styled/containers"
import CustomLink from "widgets/common/CustomLink"
import GroupCardMenu from "./GroupCardMenu"

interface GroupCardProps {
  deviceGroup: DeviceGroup
  settingsStates: AlertSettingStatesById | undefined
}

export default function GroupCard({ deviceGroup, settingsStates }: GroupCardProps) {
  const { baseOrgURL } = useRoutes()
  const { t } = useTranslation()
  const theme = useTheme()
  const { lessIdByDeviceId } = useDevices()

  const triggeredAlerts = useMemo(
    () => settingsStates && hasTriggeredAlerts(settingsStates),
    [settingsStates],
  )
  const groupColor = useMemo(
    () => getColorFromString(deviceGroup?.name ?? ""),
    [deviceGroup],
  )
  const devicesAmount = useMemo(
    () =>
      fp.flow(
        fp.get("device_list"),
        fp.filter((deviceId: number) => fp.has(deviceId, lessIdByDeviceId)),
        fp.size,
      )(deviceGroup),
    [deviceGroup, lessIdByDeviceId],
  )

  return (
    <>
      <Card
        elevation={0}
        styles={{
          backgroundColor: triggeredAlerts
            ? alpha(theme.palette.error.main, 0.2)
            : theme.palette.background.paper,
          height: "100%",
          border: `1px solid ${theme.palette.neutral[400]}`,
          borderLeft: `3px solid ${groupColor}`,
          padding: 0,
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              textAlign: "left",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            <CardContent
              sx={{
                width: "100%",
                p: "1rem 0 1rem 1rem",
              }}
            >
              <Stack spacing={1} divider={<Divider flexItem />}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  {triggeredAlerts && (
                    <Tooltip title={t("alerts.TRIGGERED_GROUP_ALERT")}>
                      <ErrorIcon
                        fontSize="small"
                        sx={{
                          color: (theme) => theme.palette.error.main,
                        }}
                        data-testid="triggered-icon"
                      />
                    </Tooltip>
                  )}
                  <CustomLink
                    variant="h6"
                    href={`${baseOrgURL}/device-groups/${deviceGroup?.id}`}
                    hover
                    bold
                  >
                    {deviceGroup?.name}{" "}
                  </CustomLink>
                </Stack>
                <Box>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    {t("generic.DEVICES")}: {devicesAmount}
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </Box>
          <Box display="flex" justifyContent="flex-end" padding="0">
            <GroupCardMenu deviceGroup={deviceGroup} />
          </Box>
        </Stack>
      </Card>
    </>
  )
}
