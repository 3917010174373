import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useDeleteGroupMutation } from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import type { DeviceGroup } from "types/group.types"

import ConfirmationPopup from "../../common/ConfirmationPopup"

interface IDeleteGroupPopupProps {
  deviceGroup: DeviceGroup
  open: boolean
  onClose: () => void
}

const DeleteGroupPopup: FC<IDeleteGroupPopupProps> = ({ deviceGroup, onClose, open }) => {
  const { t } = useTranslation()
  const orgId = useSelector(selectCurrentOrgId)
  const [deleteGroup, { isLoading }] = useDeleteGroupMutation()

  const handleDeleteGroup = () => {
    snackbarMutation({
      mutation: deleteGroup({
        group_name: deviceGroup.name,
        org_id: orgId as number,
        group_id: deviceGroup.id,
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.DELETING_ITEM", { item: t("device_groups.DEVICE_GROUP") }),
      ),
      getSuccessMessage: () =>
        t("success.DELETING_ITEM", {
          item: t("device_groups.DEVICE_GROUP"),
          count: 1,
          context: "male",
        }),
    })
      .then(() => {
        onClose()
      })
      .catch(() => {})
  }

  return (
    <ConfirmationPopup
      open={open}
      onClose={onClose}
      description={t("device_groups.ARE_YOU_SURE_TO_DELETE_GROUP", {
        groupName: deviceGroup.name,
      })}
      mainButtonText={t("device_groups.DELETE_GROUP")}
      onConfirm={handleDeleteGroup}
      isLoading={isLoading}
      isDeletePopup
    />
  )
}

export default DeleteGroupPopup
