import { Outlet, useParams } from "react-router-dom"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Stack } from "@mui/material"

import usePowerline from "helpers/hooks/powerlines/usePowerline"
import Title from "widgets/common/Title"
import LinkTabsSection from "widgets/common/LinkTabsSection"
import { getPowerlineTabsConfig } from "helpers/config/tabsConfigs"
import Spinner from "widgets/common/Spinner"
import PreviousPage from "widgets/common/PreviousPage"
import useRoutes from "helpers/hooks/useRoutes"
import { ItemTag } from "widgets/styled/widgets"

const Powerline = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { powerline, isFetching: powerlineFetching } = usePowerline({ id: Number(id) })
  const { baseOrgURL } = useRoutes()

  const powerlinesTabConfig = useMemo(() => getPowerlineTabsConfig(), [])

  return (
    <>
      {powerlineFetching && !powerline ? (
        <Spinner />
      ) : (
        <>
          <Stack>
            <PreviousPage href={`${baseOrgURL}/powerlines`} />
            <Stack
              flexDirection={"row"}
              gap={"15px"}
              alignItems={"center"}
              marginBottom={"0.5rem"}
            >
              <ItemTag>{t("powerlines.POWERLINE")}</ItemTag>
              <Title text={powerline?.name || ""} styles={{ p: 0 }} />
            </Stack>
          </Stack>
          <LinkTabsSection tabsConfig={powerlinesTabConfig} />
          <Outlet context={{ powerline, powerlineFetching }} />
        </>
      )}
    </>
  )
}

export default Powerline
