import { useTranslation } from "react-i18next"
import { Grid } from "@mui/material"
import usePowerlines from "helpers/hooks/powerlines/usePowerlines"

import Title from "widgets/common/Title"
import { HeaderContainer } from "widgets/styled/containers"
import PowerlinesTable from "widgets/powerlines/PowerlinesTable"
import PowerlineCardsSection from "widgets/powerlines/PowerlineCardsSection"

const Powerlines = () => {
  const { t } = useTranslation()
  const { powerlines, isFetching, isError } = usePowerlines()

  return (
    <>
      <HeaderContainer>
        <Title text={t("powerlines.POWERLINES")} />
      </HeaderContainer>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PowerlineCardsSection
            powerlines={powerlines?.results}
            powerlinesPending={isFetching}
            powerlinesError={isError}
          />
        </Grid>
        <Grid item xs={12}>
          <PowerlinesTable
            powerlines={powerlines?.results ?? []}
            isPending={isFetching}
            titleKey="powerlines.POWERLINES"
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Powerlines
