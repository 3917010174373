import type { FC } from "react"
import { useState } from "react"
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded"
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material"
import { useTranslation } from "react-i18next"

import usePermissions from "helpers/hooks/usePermissions"
import type { DeviceGroup } from "../../types/group.types"
import Menu from "../common/Menu"
import DeleteGroupPopup from "./editor/DeleteGroupPopup"

interface IGroupCardMenuProps {
  deviceGroup: DeviceGroup
}

const GroupCardMenu: FC<IGroupCardMenuProps> = ({ deviceGroup }) => {
  const { t } = useTranslation()
  const { isEditor } = usePermissions()

  const [deletePopupVisible, setDeletePopupVisible] = useState(false)

  /*
    At the moment, the menu only has editor/admin functionalities.
    If at some point we add any functionality shared with the viewer role,
    we would have to modify the location of this check.
  */

  if (!isEditor) {
    return <></>
  }

  return (
    <>
      <Menu>
        <MenuItem
          sx={{ color: (theme) => theme.palette.error.main }}
          onClick={() => setDeletePopupVisible(true)}
        >
          <ListItemIcon>
            <DeleteRoundedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>{t("generic.DELETE")}</ListItemText>
        </MenuItem>
      </Menu>
      <DeleteGroupPopup
        open={deletePopupVisible}
        onClose={() => setDeletePopupVisible(false)}
        deviceGroup={deviceGroup}
      />
    </>
  )
}

export default GroupCardMenu
