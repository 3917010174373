import { useTranslation } from "react-i18next"
import { Grid } from "@mui/material"

import Title from "widgets/common/Title"
import CreateGroupButton from "widgets/deviceGroups/editor/CreateGroupButton"
import { HeaderContainer } from "widgets/styled/containers"
import usePermissions from "helpers/hooks/usePermissions"
import GroupCardsSection from "widgets/deviceGroups/GroupCardsSection"

function DeviceGroups() {
  const { t } = useTranslation()
  const { isEditor } = usePermissions()

  return (
    <Grid item xs={12}>
      <HeaderContainer>
        <Title text={t("device_groups.DEVICE_GROUPS")} />
        {isEditor && <CreateGroupButton />}
      </HeaderContainer>
      <GroupCardsSection />
    </Grid>
  )
}

export default DeviceGroups
