import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"

import { formatMutationParams, reverseFormatThresholds } from "helpers/utils/alerts"
import { selectCurrentOrgId } from "features/store/orgSlice"
import { useUpdateDeviceAlertSettingMutation } from "features/api"
import { getTelemetryTranslation } from "helpers/utils/telemetries"

import type {
  AlertSetting,
  AlertSettingType,
  AlertSettingUpsertForm,
  ReverseThresholdField,
} from "types/alerts.types"

import AlertSettingForm from "./AlertSettingForm"

interface EditAlertSettingProps {
  selectedAlert: AlertSetting
  onClose: () => void
  types: AlertSettingType[]
}

function EditAlertSetting({ selectedAlert, onClose, types }: EditAlertSettingProps) {
  const { t } = useTranslation()
  const { id } = useParams()
  const orgId = useSelector(selectCurrentOrgId)
  const [updateDeviceAlertSetting, { isLoading, isError, error, isSuccess }] =
    useUpdateDeviceAlertSettingMutation()

  const selectedTypeName = selectedAlert.type
  const selectedType = useMemo(
    () => types?.find((type) => type.name === selectedTypeName),
    [types, selectedTypeName],
  )
  const defaultValues: {
    type: string
    [key: string]: Array<any> | boolean | number | string
  } = {
    type: selectedAlert.type,
    ...selectedAlert.config,
  }
  const fields = selectedAlert.config.fields as string[]
  defaultValues.fields = fields?.map((field) => {
    return {
      label: getTelemetryTranslation(field, t),
      value: field,
    }
  })

  if (defaultValues.thresholds) {
    const formattedThresholds = reverseFormatThresholds(
      selectedAlert.config.thresholds as ReverseThresholdField[],
    )
    defaultValues.thresholds = formattedThresholds
  }

  const methods = useForm<AlertSettingUpsertForm>({
    mode: "all",
    defaultValues,
  })

  const onSubmit = async (data: AlertSettingUpsertForm) => {
    const formattedData = formatMutationParams(data, selectedType)
    updateDeviceAlertSetting({
      id: selectedAlert.id,
      less_id: Number(id),
      org_id: Number(orgId),
      ...formattedData,
    })
  }

  return (
    <AlertSettingForm
      isLoading={isLoading}
      title="alert_settings.EDIT_ALERT_SETTING"
      onSubmit={methods.handleSubmit(onSubmit)}
      methods={methods}
      types={types}
      selectedTypeName={selectedTypeName}
      onClose={onClose}
      buttonText="generic.UPDATE"
      errorText="alerts.FAILED_UPDATE_ALERT"
      isError={isError}
      error={error}
      isSuccess={isSuccess}
      disableType
    />
  )
}

export default EditAlertSetting
