import type { ReactNode } from "react"
import type { TFunction } from "i18next"
import { Stack, Typography } from "@mui/material"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import LoopIcon from "@mui/icons-material/Loop"
import type { Dictionary } from "lodash"
import fp from "lodash/fp"
import type { MUISortOptions } from "mui-datatables"

import { formatDateFromMillis } from "helpers/utils/dateUtils"
import type {
  AlertSettingParam,
  AlertSettingStatesById,
  AlertSettingState,
  AlertSettingType,
  AlertSettingUpsertForm,
  AlertsRes,
  AlertsTableRow,
  AlertState,
  EventDescription,
  ReverseThresholdField,
  ThresholdsField,
} from "types/alerts.types"
import type { TelemetryOptionType } from "types/telemetries.types"
import type { OrgAlertSetting } from "types/orgs.types"
import type { InputRulesType } from "widgets/common/ControlledInput"
import CustomLink from "widgets/common/CustomLink"
import StatusBox from "widgets/common/StatusBox"
import IconTextStack from "widgets/common/IconTextStack"
import { defaultSort } from "./tables"

const DEVICE_TRIGGER = "device"
const GROUP_TRIGGER = "group"

interface Iname {
  name: string
}

export const isTriggeredState = (state: AlertSettingState | undefined): boolean => {
  const triggered = fp.getOr(false, "triggered", state)
  const silenced = fp.getOr(false, "silenced", state)
  return triggered && !silenced
}

export const hasOrgTriggeredAlerts = (
  orgActiveDeviceAlertSettings: OrgAlertSetting[] | undefined,
  statesBySettingId: { [key: string]: AlertSettingState },
): boolean => {
  return fp.flow(
    fp.defaultTo([] as OrgAlertSetting[]),
    fp.filter((setting: OrgAlertSetting) =>
      isTriggeredState(statesBySettingId[setting.id]),
    ),
    fp.negate(fp.isEmpty),
  )(orgActiveDeviceAlertSettings)
}

export const hasTriggeredAlerts = (states: AlertSettingStatesById) =>
  states && Object.values(states).some((state) => isTriggeredState(state))

export const hasDeviceTriggeredAlerts = (
  states: AlertSettingStatesById,
  orgAlertsBySettingId: { [key: string]: OrgAlertSetting },
) => {
  return fp.flow(
    fp.toPairs,
    fp.some(
      ([settingId, settingState]: [string, AlertSettingState]) =>
        isTriggeredState(settingState) &&
        Boolean(orgAlertsBySettingId[settingId]?.config?.active),
    ),
  )(states)
}

export const getAlertSettingParamRules = (param: AlertSettingParam, t: TFunction) => {
  const message = t("generic.FIELD_REQUIRED") || ""
  let result: InputRulesType = { required: param?.required ? message : false }
  if (!param.schema || !("type" in param.schema) || param.schema.type !== "number") {
    return result
  }
  const schema = param.schema
  if (schema.minimum === 0) {
    result = {
      ...result,
      min: {
        value: schema.minimum,
        message: t("generic.FIELD_MIN_VALUE", { minValue: schema.minimum }),
      },
    }
  }

  return result
}

export const formatThresholds = (thresholds: ThresholdsField[]) => {
  const result = thresholds
    .map((threshold) => {
      switch (threshold.rangeCondition) {
        case "above":
          return {
            min: +threshold[threshold.rangeCondition],
            target: threshold.alertStatus.toLowerCase(),
          }
        case "below":
          return {
            max: +threshold[threshold.rangeCondition],
            target: threshold.alertStatus.toLowerCase(),
          }
        case "inside":
          return {
            min: +threshold[threshold.rangeCondition].from,
            max: +threshold[threshold.rangeCondition].to,
            target: threshold.alertStatus.toLowerCase(),
          }
        case "outside":
          return {
            max: +threshold[threshold.rangeCondition].from,
            min: +threshold[threshold.rangeCondition].to,
            target: threshold.alertStatus.toLowerCase(),
          }
        default:
          return null
      }
    })
    .filter((threshold) => threshold !== null && threshold !== undefined)
  return result
}

export const reverseFormatThresholds = (thresholds: ReverseThresholdField[]) => {
  const result = thresholds.map((threshold) => {
    const above = threshold.max === undefined && threshold.min !== undefined
    const below = threshold.max !== undefined && threshold.min === undefined
    const inside =
      threshold.min !== undefined &&
      threshold.max !== undefined &&
      threshold.min < threshold.max

    if (above) {
      return {
        rangeCondition: "above",
        above: threshold.min?.toString(),
        alertStatus: threshold.target.toUpperCase(),
      }
    } else if (below) {
      return {
        rangeCondition: "below",
        below: threshold.max?.toString(),
        alertStatus: threshold.target.toUpperCase(),
      }
    } else if (inside) {
      return {
        rangeCondition: "inside",
        inside: {
          from: threshold.min?.toString(),
          to: threshold.max?.toString(),
        },
        alertStatus: threshold.target.toUpperCase(),
      }
    } else {
      return {
        rangeCondition: "outside",
        outside: {
          from: threshold.max?.toString(),
          to: threshold.min?.toString(),
        },
        alertStatus: threshold.target.toUpperCase(),
      }
    }
  })
  return result
}

export const formatMutationParams = (
  data: AlertSettingUpsertForm,
  settingType?: AlertSettingType,
) => {
  const { type, thresholds, fields, ...rest } = data

  // Aux functions
  const formatSchemasById = fp.flow(
    fp.flatMap(({ id, schema }) => (schema ? [[id, schema]] : [])),
    fp.fromPairs,
  )
  const tweakNumbers = (schemasById: any) =>
    fp.flow(
      fp.toPairs,
      fp.map(([key, value]) => {
        const schema = schemasById[key]
        if (schema && schema.type === "number") {
          return [key, Number(value)]
        }
        return [key, value]
      }),
      fp.fromPairs,
    )

  const typedThresholds = thresholds as ThresholdsField[]
  const typedTelemetries = fields as TelemetryOptionType[]
  const formattedTelemetries =
    typedTelemetries && typedTelemetries.map((telemetry) => telemetry.value)
  const formattedThresholds = typedThresholds && formatThresholds(typedThresholds)

  const schemasById = formatSchemasById(settingType?.params)
  const formattedConfig = tweakNumbers(schemasById)(rest)

  return {
    config: {
      ...formattedConfig,
      ...(formattedThresholds && {
        thresholds: formattedThresholds,
      }),
      ...(formattedTelemetries && {
        fields: formattedTelemetries,
      }),
    },
    type,
  }
}

const getEventDescription = (
  triggered: boolean | undefined,
  resolved: boolean | undefined,
): EventDescription => {
  switch (true) {
    case triggered:
      return {
        icon: <ErrorOutlineIcon color="error" fontSize="small" />,
        description: "alerts.EVENT_TRIGGERED",
      }
    case resolved:
      return {
        icon: <CheckCircleOutlineIcon color="success" fontSize="small" />,
        description: "alerts.EVENT_RESOLVED",
      }
    default:
      return {
        icon: <LoopIcon color="warning" fontSize="small" />,
        description: "alerts.EVENT_RENOTIFICATION",
      }
  }
}

export const getEventsTableRows = (alerts: AlertsRes, t: TFunction) => {
  return alerts?.map((alert) => {
    const { icon, description } = getEventDescription(alert.triggered, alert.resolved)
    return {
      eventDescription: {
        icon,
        description: t(description, { alertName: alert.alert_name }),
      },
      timestamp: formatDateFromMillis(alert.timestamp),
    }
  })
}

export const getAlertsTableColumns = (
  baseOrgURL: string,
  devicesIds: number[] | undefined,
  groupId: number | undefined,
  t: TFunction,
) => {
  return [
    {
      name: "label",
      label: t("alerts.LABEL"),
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value: string) => (
          <StatusBox status="neutral-light">{value}</StatusBox>
        ),
      },
    },
    {
      name: "trigger",
      label: t("alerts.TRIGGER"),
      options: {
        filter: false,
        sort: true,
        display: devicesIds && !groupId ? false : true,
        setCellProps: () => ({ style: { minWidth: "200px" } }),
        customBodyRender: (value: {
          trigger: string
          relatedId: number
          relatedName: string
        }) => {
          const href =
            value?.trigger === DEVICE_TRIGGER
              ? `${baseOrgURL}/devices/${value?.relatedId}`
              : `${baseOrgURL}/device-groups/${value?.relatedId}`
          return (
            <Stack direction={"column"}>
              <Stack direction={"row"} flexWrap={"wrap"}>
                <Typography color="text.secondary">
                  {`${t("generic.NAME")}: `}&nbsp;
                </Typography>
                <CustomLink href={href} hover bold>
                  {value.relatedName}
                </CustomLink>
              </Stack>
              <Stack direction={"row"} gap={1}>
                <Typography color="text.secondary" flexWrap={"wrap"}>
                  {`${t("generic.ID")}:`}
                </Typography>
                <Typography fontWeight="600">{value.relatedId}</Typography>
              </Stack>
            </Stack>
          )
        },
        sortCompare: (order: MUISortOptions["direction"]) =>
          defaultSort(order, "relatedName"),
      },
    },
    {
      name: "eventDescription",
      label: t("alerts.EVENT_DESCRIPTION"),
      options: {
        filter: false,
        sort: true,
        display: true,
        setCellProps: () => ({ style: { minWidth: "250px" } }),
        customBodyRender: (value: {
          description: string
          icon: ReactNode
          eventName: string
        }) => {
          return value.description ? (
            <IconTextStack icon={value.icon} text={value.description} />
          ) : (
            <Typography>{value.eventName}</Typography>
          )
        },
        sortCompare: (order: MUISortOptions["direction"]) =>
          defaultSort(order, "description"),
      },
    },
    {
      name: "timestamp",
      label: t("generic.TIMESTAMP"),
      options: {
        setCellProps: () => ({ style: { minWidth: "150px" } }),
        filter: false,
        sort: true,
        display: true,
      },
    },
  ]
}

export const getAlertsTableRows = (alerts: AlertsRes, t: TFunction) => {
  return alerts?.map((alert) => {
    const relatedId = alert.less_id ? alert.less_id : alert.group_id
    const relatedName = alert.device_name ? alert.device_name : alert.group_name
    const trigger = alert.device_name ? DEVICE_TRIGGER : GROUP_TRIGGER
    const { icon, description } = getEventDescription(alert.triggered, alert.resolved)
    const timestamp = formatDateFromMillis(alert.timestamp)
    return {
      label: trigger === DEVICE_TRIGGER ? t("generic.DEVICE") : t("device_groups.GROUP"),
      trigger: { trigger, relatedId, relatedName },
      eventDescription: {
        icon,
        description: t(description, { alertName: alert.alert_name }),
      },
      timestamp,
    }
  })
}

export const getColsByName = fp.flow(
  (data) => fp.zip(fp.range(0, data.length), data),
  fp.map(([i, column]) => [(column as Iname).name, i]),
  fp.fromPairs,
)

export const getAlertState = (alertState: AlertSettingState): AlertState => {
  return alertState.silenced && alertState.triggered
    ? "silenced"
    : alertState.triggered
    ? "open"
    : "notTriggered"
}

export const alertsCustomSearch = (
  searchQuery: string,
  currentRow: AlertsTableRow,
  columnsIndexes: Dictionary<number>,
) => {
  const searchText = searchQuery.toLowerCase()
  const trigger = currentRow[columnsIndexes.trigger] as {
    relatedId: number
    relatedName: string
    trigger: string
  }
  const eventDescription = currentRow[columnsIndexes.eventDescription] as {
    description: string
    icon: JSX.Element
  }
  const searchInTrigger =
    trigger.relatedId.toString().includes(searchText) ||
    trigger.relatedName.toLowerCase().includes(searchText)
  const searchInEventDescription = eventDescription.description
    .toLowerCase()
    .includes(searchText)

  const isFound = currentRow.some(
    (col) =>
      col.toString().toLowerCase().includes(searchText) ||
      searchInTrigger ||
      searchInEventDescription,
  )
  return isFound
}

export const formatAlertsTableCsv = (
  data: { index: number; data: AlertsTableRow }[],
  columnsIndexes: Dictionary<number>,
  t: TFunction,
) => {
  return data?.map((row, index: number) => ({
    index,
    data: row?.data?.map((field: any, index) => {
      // field can be any of the types of AlertsTableRows
      // array order comes from columns definition
      switch (index) {
        case columnsIndexes.trigger:
          return `${field.relatedName} - ${field.relatedId}`
        case columnsIndexes.eventDescription:
          return field.description
        case columnsIndexes.viewed:
          return field ? t("alerts.VIEWED") : t("alerts.NOT_VIEWED")
        default:
          return field
      }
    }),
  }))
}
