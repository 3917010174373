import type { Middleware } from "@reduxjs/toolkit"
import type { BaseQueryFn } from "@reduxjs/toolkit/query/react"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { stringify as qsStringify } from "query-string"

import type {
  OrgsReq,
  OrgsRes,
  UpdateOrgAuthMechanismsReq,
  UpdateOrgNameReq,
  UpdateOrgSettingsReq,
} from "types/orgs.types"
import type { DeviceTelemetryReq, DeviceTelemetryRes } from "types/telemetries.types"
import type {
  RemoveOrgUserReq,
  OrgUsersReq,
  OrgUsersRes,
  UpdateOrgUserReq,
  UpdateOrgUserRes,
  UsersLogsReq,
  UsersLogsRes,
} from "types/users.types"
import type {
  GeoReq,
  GeoRes,
  LastLocationsReq,
  LastLocationsRes,
  UpdateLocationReq,
} from "types/geolocation.types"
import type {
  CellDataReq,
  CellDataRes,
  CellViewReq,
  CellViewRes,
  DashboardByDeviceReq,
  DashboardByDeviceRes,
} from "types/dashboard.types"
import type { Paged } from "types/common.types"
import { API_URL } from "../config"
import { invalidateCredentials } from "../store/authSlice"
import type { RootState } from "../store"

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState = () => ({}) }) => {
    const state = getState() as RootState
    const token = state?.auth?.token
    const newHeaders = new Headers(headers)
    if (token) {
      newHeaders.set("authorization", `Bearer ${token}`)
    }
    return newHeaders
  },
  paramsSerializer: qsStringify,
})
const baseQueryWithTokenInvalidation: BaseQueryFn = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // Invalidate credentials
    api.dispatch(invalidateCredentials())
  }
  return result
}

export const api = createApi({
  baseQuery: baseQueryWithTokenInvalidation,
  tagTypes: [
    "ERROR",
    "UNAUTHORIZED",
    "Alert",
    "AlertSetting",
    "AlertSettingState",
    "Conductor",
    "Device",
    "DeviceGroup",
    "DeviceId",
    "DeviceLocation",
    "Organization",
    "OrgFeaturesLocation",
    "OrgUsersLogs",
    "Powerline",
    "Span",
    "Tower",
    "User",
  ],
  endpoints: (builder) => ({
    geo: builder.query<GeoRes, GeoReq>({
      query: ({ orgId }) => ({
        url: `/v1/orgs/${encodeURIComponent(orgId)}/geo`,
      }),
    }),
    lastLocations: builder.query<LastLocationsRes, LastLocationsReq>({
      query: ({ params }) => ({
        url: "/v1/gps_locations",
        params,
      }),
      providesTags: (_result, _error, { params }) => {
        const ids = (params?.less_ids || []).slice()
        if (params?.less_id) {
          ids.push(params.less_id)
        }
        return ids.map((id) => ({ type: "DeviceLocation" as const, id }))
      },
    }),
    updateLocation: builder.mutation<null, UpdateLocationReq>({
      query: ({ deviceId, latitude, longitude }) => ({
        url: "/v1/gps_locations",
        method: "POST",
        body: { less_id: deviceId, latitude, longitude },
      }),
      invalidatesTags: (_result, _error, { deviceId: id }) => [
        { type: "DeviceLocation" as const, id },
      ],
    }),
    dashboardByDevice: builder.query<DashboardByDeviceRes, DashboardByDeviceReq>({
      query: ({ id }) => ({
        url: `/v1/devices/${encodeURIComponent(id)}/dashboard`,
      }),
      keepUnusedDataFor: 600,
    }),
    deviceTelemetry: builder.query<DeviceTelemetryRes, DeviceTelemetryReq>({
      query: ({ id, params }) => ({
        url: `/v1/devices/${encodeURIComponent(id)}/telemetry`,
        params: {
          ...params,
          format: "json",
        },
      }),
    }),
    cellView: builder.query<CellViewRes, CellViewReq>({
      query: ({ dashboardId, id }) => ({
        url: `/v1/dashboards/${encodeURIComponent(
          dashboardId,
        )}/cells/${encodeURIComponent(id)}`,
      }),
      keepUnusedDataFor: 300,
    }),
    cellData: builder.query<CellDataRes, CellDataReq>({
      query: ({ dashboardId, id, params }) => ({
        url: `/v1/dashboards/${encodeURIComponent(
          dashboardId,
        )}/cells/${encodeURIComponent(id)}/data`,
        params,
      }),
    }),
    orgs: builder.query<OrgsRes, OrgsReq>({
      query: () => ({
        url: "/v1/orgs",
        params: { extras: ["roles"] },
      }),
      providesTags: (result, _error, { user = null } = {}) => {
        return result
          ? [
              ...result.orgs.map(({ id }) => ({ type: "Organization" as const, id })),
              { type: "Organization", id: `User(${user})` },
            ]
          : [{ type: "Organization", id: `User(${user})` }]
      },
    }),
    orgUsers: builder.query<OrgUsersRes, OrgUsersReq>({
      query: ({ orgId }) => ({
        url: `/v1/orgs/${orgId}/users`,
      }),
      providesTags: (result, _error, { orgId }) => {
        return result
          ? [
              ...result.users.map(({ username }) => ({
                type: "User" as const,
                id: username,
              })),
              { type: "User", id: `Organization(${orgId})` },
            ]
          : [{ type: "User", id: `Organization(${orgId})` }]
      },
    }),
    removeOrgUser: builder.mutation<null, RemoveOrgUserReq>({
      query: ({ orgId, username }) => ({
        url: `/v1/orgs/${orgId}/users/${encodeURIComponent(username)}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { orgId }) => [
        { type: "User", id: `Organization(${orgId})` },
      ],
    }),
    updateOrgUser: builder.mutation<UpdateOrgUserRes, UpdateOrgUserReq>({
      query: ({ orgId, username, role }) => ({
        url: `/v1/orgs/${orgId}/users/${encodeURIComponent(username)}`,
        method: "PATCH",
        body: {
          role,
        },
      }),
      invalidatesTags: (_result, _error, { orgId }) => [
        { type: "User", id: `Organization(${orgId})` },
      ],
    }),
    updateOrgName: builder.mutation<null, UpdateOrgNameReq>({
      query: ({ name, orgId }) => ({
        url: `/v1/orgs/${encodeURIComponent(orgId)}`,
        method: "PATCH",
        body: { name },
      }),
      invalidatesTags: (_result, error, { orgId: id }) => {
        return !error ? [{ type: "Organization", id }] : []
      },
    }),
    updateOrgSettings: builder.mutation<null, UpdateOrgSettingsReq>({
      query: ({ emails, language, orgId }) => ({
        url: `/v1/orgs/${encodeURIComponent(orgId)}`,
        method: "PATCH",
        body: { emails, language },
      }),
      invalidatesTags: (_result, error, { orgId: id }) => {
        return !error ? [{ type: "Organization", id }] : []
      },
    }),
    updateOrgAuthMechanisms: builder.mutation<null, UpdateOrgAuthMechanismsReq>({
      query: ({ auth_mechanisms, orgId }) => ({
        url: `/v1/orgs/${encodeURIComponent(orgId)}`,
        method: "PATCH",
        body: { auth_mechanisms },
      }),
      invalidatesTags: (_result, error, { orgId: id }) => {
        return !error ? [{ type: "Organization", id }] : []
      },
    }),
    orgUsersLogs: builder.query<Paged<UsersLogsRes>, UsersLogsReq>({
      query: ({ org_id, from_date, to_date, cursor, limit }) => ({
        url: `/v1/orgs/${org_id}/users_logs`,
        params: { from_date, to_date, cursor, limit },
      }),
      providesTags: (_result, _error, { org_id }) => [
        { type: "OrgUsersLogs", id: `Organization(${org_id})` },
      ],
    }),
  }),
})

export const apiMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action)
  switch (action.type) {
    case "auth/invalidateCredentials": {
      store.dispatch(api.util.resetApiState())
      break
    }
    default:
      break
  }
  return result
}
