import { styled } from "@mui/material/styles"
import { Popup as LPopup, Tooltip } from "react-leaflet"

import type { IDefaultStyledProps } from "types/styled.types"

export const MarkerTooltip = styled(Tooltip, {
  shouldForwardProp: (prop) => prop !== "styles",
})<IDefaultStyledProps>(({ theme, styles }) => ({
  border: "none",
  boxShadow: "none",
  borderRadius: "none",
  fontWeight: 600,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  "&:before": {
    opacity: 0,
  },
  ...styles,
}))

export const Popup = styled(LPopup)(({ theme }) => ({
  borderRadius: 0,
  padding: 0,
  minWidth: "250px",
  "& .leaflet-popup-content-wrapper": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: "5px",
    padding: "1rem 1rem 0.75rem 1rem",
  },
  "& .leaflet-popup-tip-container": {
    visibility: "hidden",
  },
  "& .leaflet-popup-content": {
    margin: 0,
    "& p": {
      margin: "0.5rem 0 0 0",
    },
  },
}))
