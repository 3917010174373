import { lazy, Suspense } from "react"
import { Navigate, Route, Routes as RouterRoutes, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

import { selectCurrentUser } from "features/store/authSlice"
import { DEFAULT_TARGET } from "helpers/utils/constants"
import { DashboardLayout } from "layouts/DashboardLayout"
import { PublicLayout } from "layouts/PublicLayout"
import DeviceData from "pages/orgs/Device/DeviceData"
import MainDashboard from "pages/orgs/Device/MainDashboard"
import Login from "pages/accounts/Login"
import LoginOAuth from "pages/accounts/LoginOAuth"
import Logout from "pages/accounts/Logout"
import Register from "pages/accounts/Register"
import Device from "pages/orgs/Device"
import DeviceAlerts from "pages/orgs/Device/DeviceAlerts"
import DeviceEventsLog from "pages/orgs/Device/DeviceEventsLog"
import DeviceGroup from "pages/orgs/DeviceGroup"
import DeviceGroups from "pages/orgs/DeviceGroups"
import DevicePosition from "pages/orgs/Device/DevicePosition"
import DeviceSettings from "pages/orgs/Device/DeviceSettings"
import GroupAlerts from "pages/orgs/DeviceGroup/GroupAlerts"
import GroupEventsLog from "pages/orgs/DeviceGroup/GroupEventsLog"
import GroupInfo from "pages/orgs/DeviceGroup/GroupInfo"
import PlotsNew from "pages/orgs/PlotsNew"
import OrgSettings from "pages/orgs/OrgSettings"
import OrgHomepage from "pages/orgs"
import UserHomepage from "pages/users"
import CreateToken from "pages/users/CreateToken"
import Api from "pages/documentation/api"
import ApiDoc from "pages/documentation/api/ApiDoc"
import BoardLoader from "pages/BoardLoader"
import PasswordRecover from "pages/accounts/PasswordRecover"
import ActivateUser from "pages/accounts/ActivateUser"
import AlertsList from "pages/orgs/Alerts/AlertsList"
import EventsLog from "pages/orgs/Alerts/EventsLog"
import Alerts from "pages/orgs/Alerts"
import AccountGeneral from "pages/users/AccountSettings/AccountGeneral"
import AccountSecurity from "pages/users/AccountSettings/AccountSecurity"
import AccountSettings from "pages/users/AccountSettings"
import ProductDocs from "pages/documentation/product"
import AuditTrail from "pages/orgs/AuditTrail"
import Map from "pages/orgs/Map"
import TermsNConditions from "pages/public/TermsNConditions"
import Preloader from "widgets/common/PreLoader"
import Powerlines from "pages/orgs/Powerlines"
import Powerline from "pages/orgs/Powerline"
import PowerlineInfo from "pages/orgs/Powerline/PowerlineInfo"

const BaseLayout = lazy(() =>
  import("layouts").then((module) => ({
    default: module.BaseLayout,
  })),
)
const RecoverPasswordLazy = lazy(() => import("pages/accounts/Recover"))
const ModifyPasswordLazy = lazy(() => import("pages/users/ModifyPassword"))
const QRPageLazy = lazy(() => import("pages/QRPage"))

export default function Routes() {
  const username = useSelector(selectCurrentUser)

  const location = useLocation()

  return (
    <RouterRoutes>
      <Route path="/accounts" element={<PublicLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="oauth-login" element={<LoginOAuth />} />
        <Route path="register" element={<Register />} />
        <Route
          path="recover"
          element={
            <Suspense fallback={<Preloader />}>
              <RecoverPasswordLazy />
            </Suspense>
          }
        />
        <Route path=":username/password-recover/:code" element={<PasswordRecover />} />
        <Route path=":username/activate/:secret" element={<ActivateUser />} />
      </Route>
      <Route
        path="/qr"
        element={
          <Suspense fallback={<Preloader />}>
            <QRPageLazy />
          </Suspense>
        }
      />
      <Route path="/users/:username/modify-password" element={<PublicLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<Preloader />}>
              <ModifyPasswordLazy />
            </Suspense>
          }
        />
      </Route>
      <Route path="terms-and-conditions" element={<TermsNConditions />} />

      <Route
        path="/"
        element={
          username ? (
            <BoardLoader />
          ) : (
            <Navigate to="/accounts/login" state={{ redirectedFrom: DEFAULT_TARGET }} />
          )
        }
      />

      <Route
        element={
          username ? (
            <Suspense fallback={<Preloader />}>
              <BaseLayout />
            </Suspense>
          ) : (
            <Navigate
              to="/accounts/login"
              state={{ redirectedFrom: `${location.pathname}${location.search}` }}
            />
          )
        }
      >
        <Route element={<DashboardLayout />}>
          <Route path="/users/:username">
            <Route index element={<UserHomepage />} />
            <Route path="settings" element={<AccountSettings />}>
              <Route index element={<AccountGeneral />} />
              <Route path="security" element={<AccountSecurity />} />
            </Route>
          </Route>

          <Route path="/orgs/:orgId">
            <Route index element={<OrgHomepage />} />
            <Route path="powerlines" element={<Powerlines />} />
            <Route path="powerlines/:id" element={<Powerline />}>
              <Route index element={<PowerlineInfo />} />
            </Route>
            <Route path="device-groups" element={<DeviceGroups />} />
            <Route path="device-groups/:id" element={<DeviceGroup />}>
              <Route index element={<GroupInfo />} />
              <Route path="alerts" element={<GroupAlerts />} />
              <Route path="events-log" element={<GroupEventsLog />} />
            </Route>
            <Route path="devices/:id" element={<Device />}>
              <Route index element={<MainDashboard />} />
              <Route path="position" element={<DevicePosition />} />
              <Route path="device-data" element={<DeviceData />} />
              <Route path="alerts" element={<DeviceAlerts />} />
              <Route path="events-log" element={<DeviceEventsLog />} />
              <Route path="settings" element={<DeviceSettings />} />
            </Route>
            <Route path="alerts" element={<Alerts />}>
              <Route index element={<AlertsList />} />
              <Route path="events-log" element={<EventsLog />} />
            </Route>
            {/* recharts plots tests/demo, not linked anywhere */}
            <Route path="plots-new" element={<PlotsNew />} />
            <Route path="administration/organization" element={<OrgSettings />} />
            <Route path="administration/audit-trail" element={<AuditTrail />} />
          </Route>
          <Route path="documentation/api" element={<Api />}>
            <Route index element={<ApiDoc />} />
            <Route path="create-token" element={<CreateToken />} />
          </Route>
          <Route path="documentation/product" element={<ProductDocs />} />
        </Route>
        <Route element={<DashboardLayout fullWidth />}>
          <Route path="/orgs/:orgId">
            <Route path="map" element={<Map />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </RouterRoutes>
  )
}
