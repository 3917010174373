import type { FC } from "react"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import type { MUISortOptions } from "mui-datatables"

import type { DeviceGroupRef } from "../../../types/group.types"
import type { DeviceWGroupsNStates } from "../../../types/device.types"

import { defaultSort, getGroupsString } from "../../../helpers/utils/tables"
import DevicesTable from "../../device/DevicesTable"
import GroupsChips from "../GroupsChips"

interface IAddDevicesToGroupTableProps {
  devices: DeviceWGroupsNStates[]
  isLoading: boolean
  handleSelect: (deviceIds: number[], dataIndexes: number[]) => void
  rowsSelected: Array<number> | undefined
}

const AddDevicesToGroupTable: FC<IAddDevicesToGroupTableProps> = ({
  devices,
  isLoading,
  handleSelect,
  rowsSelected,
}) => {
  const { t } = useTranslation()
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [page, setPage] = useState(0)
  const [sortOrder, setSortOrder] = useState<{ name: string; direction: "asc" | "desc" }>(
    {
      name: "name",
      direction: "asc",
    },
  )

  const columns = useMemo(
    () => [
      {
        name: "name",
        label: t("generic.DEVICE_NAME"),
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value: { name: string; id: number }) => {
            return <>{value.name}</>
          },
          sortCompare: (order: MUISortOptions["direction"]) => defaultSort(order),
        },
      },
      {
        name: "deviceId",
        label: t("device.DEVICE_ID"),
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "groups",
        label: t("device_groups.DEVICE_GROUPS"),
        options: {
          filter: false,
          sort: true,
          sortCompare: (order: MUISortOptions["direction"]) => {
            return (
              obj1: { data: DeviceGroupRef[] },
              obj2: { data: DeviceGroupRef[] },
            ) => {
              const obj1GroupString = getGroupsString(obj1?.data)
              const obj2GroupString = getGroupsString(obj2?.data)

              return order === "asc"
                ? obj1GroupString.localeCompare(obj2GroupString)
                : obj2GroupString.localeCompare(obj1GroupString)
            }
          },
          customBodyRender: (groups: DeviceGroupRef[]) =>
            groups ? <GroupsChips groups={groups} /> : <></>,
        },
      },
    ],
    [t],
  )

  const onRowSelectionChange = useCallback(
    (
      _currentRowsSelected: Array<any>,
      allRowsSelected: Array<any>,
      _rowsSelected: Array<any>,
      rows: Array<{ deviceId: number }>,
    ) => {
      const dataIndexes = allRowsSelected.map((selectedRow) => selectedRow.dataIndex)
      const selectedDeviceIds = dataIndexes.map((index) => rows[index]["deviceId"])
      handleSelect(selectedDeviceIds, dataIndexes)
    },
    [handleSelect],
  )

  return (
    <DevicesTable
      styles={{ padding: 0 }}
      devices={devices}
      customColumns={columns}
      isLoading={isLoading}
      onRowSelectionChange={onRowSelectionChange}
      options={{
        onRowClick: () => {},
        print: false,
        download: false,
        viewColumns: false,
        rowsSelected,
        selectableRows: "multiple",
        elevation: 0,
        customToolbarSelect: () => {
          return <></>
        },
        /* Override persisted values */
        rowsPerPage,
        onChangeRowsPerPage: setRowsPerPage,
        page,
        onChangePage: setPage,
        sortOrder,
        onColumnSortChange: (name: string, direction: "desc" | "asc") =>
          setSortOrder({ name, direction }),
      }}
    />
  )
}

export default AddDevicesToGroupTable
