import { useMemo } from "react"
import { Grid } from "@mui/material"
import fp from "lodash/fp"

import useAlertSettings from "helpers/hooks/useAlertSettings"
import useAlertSettingsStates from "helpers/hooks/useAlertSettingsStates"
import {
  devicesAlertStatusPlotDef,
  devicesStatusPlotDef,
} from "helpers/config/plots/pieDefs"
import useDevices from "helpers/hooks/useDevices"
import { getDevicesByAlertStatus, getDevicesByStatus } from "helpers/utils/devices"
import HomePieChartsSkeleton from "widgets/skeletons/HomePieChartsSkeleton"
import OrgPieChart from "./OrgPieChart"

const OrgPieCharts = () => {
  const { devsWRelsById, isLoading: devicesLoading, isError: devicesError } = useDevices()
  const {
    allAlerts,
    isLoading: statesLoading,
    isError: statesError,
  } = useAlertSettingsStates()
  const {
    orgAlertsBySettingId,
    isLoading: settingsLoading,
    isError: settingsError,
  } = useAlertSettings()

  const isLoading = useMemo(
    () => devicesLoading || statesLoading || settingsLoading,
    [devicesLoading, settingsLoading, statesLoading],
  )
  const isError = useMemo(
    () => devicesError || statesError || settingsError,
    [devicesError, settingsError, statesError],
  )

  const { areExistingDevices, devicesByStatus } = useMemo(
    () => ({
      areExistingDevices: !fp.isEmpty(devsWRelsById),
      devicesByStatus: getDevicesByStatus(devsWRelsById),
    }),
    [devsWRelsById],
  )
  const devicesByAlertStatus = useMemo(
    () => getDevicesByAlertStatus(devsWRelsById, allAlerts, orgAlertsBySettingId),
    [allAlerts, devsWRelsById, orgAlertsBySettingId],
  )

  return (
    <>
      {isLoading ? (
        <HomePieChartsSkeleton />
      ) : (
        areExistingDevices && (
          <Grid item xs={12} data-testid="pie-charts-grid">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <OrgPieChart
                  view={devicesStatusPlotDef.view}
                  data={devicesByStatus}
                  isError={devicesError}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <OrgPieChart
                  view={devicesAlertStatusPlotDef.view}
                  data={devicesByAlertStatus}
                  isError={isError}
                />
              </Grid>
            </Grid>
          </Grid>
        )
      )}
    </>
  )
}

export default OrgPieCharts
