import type { FC } from "react"
import { useSelector } from "react-redux"
import { Grid } from "@mui/material"

import DeviceGroups from "pages/orgs/DeviceGroups"
import OrgPieCharts from "widgets/organization/pieCharts/OrgPieCharts"
import OrgDevices from "widgets/device/OrgDevices"
import Title from "widgets/common/Title"
import { selectCurrentOrgName } from "features/store/orgSlice"

const OrgHomepage: FC = () => {
  const orgName = useSelector(selectCurrentOrgName)

  return (
    <>
      <Title text={String(orgName)} styles={{ marginBottom: "1rem" }} />
      <Grid container spacing={3}>
        <OrgPieCharts />
        <DeviceGroups />
        <OrgDevices />
      </Grid>
    </>
  )
}

export default OrgHomepage
