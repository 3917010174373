import type { FC } from "react"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"

import Message from "widgets/common/Message"
import type { Powerline } from "types/powerlines.types"
import PowerlineCardSkeleton from "widgets/skeletons/PowerlineCardSkeleton"
import PowerlineCard from "./PowerlineCard"

interface PowerlineCardsSectionProps {
  powerlines: Powerline[] | undefined
  powerlinesPending: boolean
  powerlinesError: boolean
}

const PowerlineCardsSection: FC<PowerlineCardsSectionProps> = ({
  powerlines,
  powerlinesPending,
  powerlinesError,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {powerlinesPending ? (
        <PowerlineCardSkeleton />
      ) : powerlinesError ? (
        <Message
          messageKey={"error.FETCHING_ITEM"}
          messageOptions={{
            item: t("powerlines.POWERLINES").toLowerCase(),
          }}
          type="error"
        />
      ) : (
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          overflow={"hidden"}
          mb={"0.5rem"}
        >
          {powerlines?.map((powerline: Powerline) => {
            return (
              <Grid item xs={12} sm={6} md={4} xl={3} key={powerline.id}>
                <PowerlineCard powerline={powerline} />
              </Grid>
            )
          })}
        </Grid>
      )}
    </>
  )
}

export default PowerlineCardsSection
