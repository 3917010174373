import { useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"
import Container from "@mui/material/Container"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import fp from "lodash/fp"

import { useDeviceAlertTypesQuery } from "features/api"
import useAlertSettingsStates from "helpers/hooks/useAlertSettingsStates"
import useAlertSettings from "helpers/hooks/useAlertSettings"
import type { AlertSetting, AlertSettingType } from "types/alerts.types"
import Message from "widgets/common/Message"
import CardWithTitle from "widgets/common/CardWithTitle"
import usePermissions from "helpers/hooks/usePermissions"
import AddAlertSetting from "./AddAlertSetting"
import AlertSettingCard from "./AlertSettingCard"
import EditAlertSetting from "./EditAlertSetting"
import AlertsSettingsSkeleton from "./AlertsSettingsSkeleton"

function AlertSettings() {
  const { isEditor } = usePermissions()
  const [creatingAlert, setCreatingAlert] = useState(false)
  const [editingAlert, setEditingAlert] = useState(false)
  const [selectedAlert, setSelectedAlert] = useState<AlertSetting | undefined>(undefined)
  const { id } = useOutletContext<{ id: string }>()
  const { i18n } = useTranslation()
  const userLanguage = i18n.resolvedLanguage
  const { alertSettingsByDeviceId, isLoading: settingsLoading } = useAlertSettingsStates()
  const { orgAlertsBySettingId } = useAlertSettings()
  const alertSettings: AlertSetting[] = useMemo(
    () =>
      fp.flow(
        fp.get(id),
        fp.defaultTo([] as number[]),
        fp.filter((deviceAlertSettingId) =>
          fp.has(deviceAlertSettingId, orgAlertsBySettingId),
        ),
        fp.map((deviceAlertSettingId) =>
          fp.get(deviceAlertSettingId, orgAlertsBySettingId),
        ),
      )(alertSettingsByDeviceId),
    [orgAlertsBySettingId, alertSettingsByDeviceId, id],
  )

  const { data: alertTypes, isLoading: typesLoading } = useDeviceAlertTypesQuery({
    lessId: Number(id),
    params: {
      lang: userLanguage,
    },
  })

  const sortedAlertTypes = useMemo(
    () => fp.sortBy((type: AlertSettingType) => type.title)(alertTypes),
    [alertTypes],
  )

  const onCreateClick = () => {
    setCreatingAlert(true)
  }
  const onCreateClose = () => {
    setCreatingAlert(false)
  }
  const onEditOpen = () => {
    setEditingAlert(true)
    setCreatingAlert(false)
  }
  const onEditClose = () => {
    setEditingAlert(false)
  }
  const isAlertCreationAllowed = useMemo(
    () => creatingAlert || editingAlert,
    [creatingAlert, editingAlert],
  )

  return (
    <CardWithTitle
      titleKey={"alerts.ALERTS_SETTINGS"}
      actionPermission={isEditor}
      isDoingAction={isAlertCreationAllowed}
      handleAction={onCreateClick}
      actionIcon={<AddCircleOutlineIcon fontSize="small" />}
    >
      <Container maxWidth="xl" disableGutters sx={{ minHeight: "200px" }}>
        {creatingAlert && (
          <AddAlertSetting types={sortedAlertTypes} onClose={onCreateClose} />
        )}
        {editingAlert && selectedAlert ? (
          <EditAlertSetting
            selectedAlert={selectedAlert}
            onClose={onEditClose}
            types={sortedAlertTypes}
          />
        ) : settingsLoading || typesLoading ? (
          <AlertsSettingsSkeleton />
        ) : (
          <>
            {alertSettings?.length === 0 && !creatingAlert && (
              <Message messageKey={"alerts.NO_ALERTS_SETTINGS"} />
            )}
            <Grid container spacing={2}>
              {sortedAlertTypes &&
                alertSettings.length !== 0 &&
                alertSettings.map((alertSetting) => (
                  <Grid key={alertSetting.id} item xs={12} sm={6} lg={4}>
                    <AlertSettingCard
                      alertSetting={alertSetting}
                      alertTypes={sortedAlertTypes}
                      onOpen={onEditOpen}
                      setSelectedAlert={setSelectedAlert}
                    />
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </Container>
    </CardWithTitle>
  )
}

export default AlertSettings
