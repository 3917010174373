import { useMemo, type FC } from "react"

import type {
  PowerlineFeatureProps,
  PowerlineSpanFeatureProps,
  PowerlineTowerFeatureProps,
  DeviceMarker,
} from "types/geolocation.types"
import DevicePopup from "./DevicePopup"
import PowerlinePopup from "./PowerlinePopup"
import SpanPopup from "./SpanPopup"
import TowerPopup from "./TowerPopup"

interface IGeoPopupProps {
  type: "powerline" | "device" | "span" | "tower"
  data:
    | DeviceMarker
    | PowerlineFeatureProps
    | PowerlineSpanFeatureProps
    | PowerlineTowerFeatureProps
}

const GeoPopup: FC<IGeoPopupProps> = ({ data, type }) => {
  const PopupContent = useMemo(() => {
    switch (type) {
      case "device": {
        return <DevicePopup data={data as DeviceMarker} />
      }
      case "powerline": {
        return <PowerlinePopup data={data as PowerlineFeatureProps} />
      }
      case "span": {
        return <SpanPopup data={data as PowerlineSpanFeatureProps} />
      }
      case "tower": {
        return <TowerPopup data={data as PowerlineTowerFeatureProps} />
      }
      default: {
        return <></>
      }
    }
  }, [type, data])

  return data && <div data-testid="geo-popup">{PopupContent}</div>
}

export default GeoPopup
