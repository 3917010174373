import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useTheme, Stack } from "@mui/material"
import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"

import line from "images/line.svg"
import { degreesToString } from "helpers/utils/common"

import type { DeviceDashboardOutletContext } from "types/dashboard.types"

import { getDeviceVersionAndTarget, isVersionAboveOrEqual } from "helpers/utils/devices"
import {
  DeviceViewContainer,
  GridContainer,
  PlotContainer,
  YAxisContainer,
} from "../styled/containers"
import { SmallData } from "../styled/widgets"

export interface DevicePositionPlotValues {
  _time: number
  alpha: number
  beta: number
}

interface IDevicePositionPlotProps {
  values: DevicePositionPlotValues
  isLatest?: boolean
}

function DevicePositionPlot({ values, isLatest }: IDevicePositionPlotProps) {
  const { device } = useOutletContext<DeviceDashboardOutletContext>()
  const { alpha, beta, _time } = values
  const { t } = useTranslation()
  const theme = useTheme()

  const [alphaViewSrc, betaViewSrc] = useMemo(() => {
    const { version, target } = getDeviceVersionAndTarget(device)
    const isNewSentri = isVersionAboveOrEqual(version, target)

    const alphaViewSrc = isNewSentri
      ? "/images/sentrisense/sentri-device-v1.png"
      : "/images/sentrisense/sentrisense-device-v1.png"
    const betaViewSrc = isNewSentri
      ? "/images/sentrisense/sentri-device-v2-side2.png"
      : "/images/sentrisense/sentrisense-device-v2.png"

    return [alphaViewSrc, betaViewSrc]
  }, [device])

  return (
    <PlotContainer
      type=""
      disableGutters
      maxWidth="xl"
      styles={{
        height: "fit-content",
        marginTop: "1rem",
        padding: "2rem 2rem 2rem 2rem",
        marginLeft: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "middle",
          flexDirection: "column",
          [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
          },
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            {`${t("device_information.POSITION")}:`}
          </Typography>
          <Typography variant="body1">
            {DateTime.fromMillis(_time).toFormat("yyyy-MM-dd HH:mm:ss")}{" "}
            {isLatest && <span>({`${t("device_information.LAST_POSITION")}`})</span>}
          </Typography>
        </Box>
      </Box>
      <GridContainer>
        <DeviceViewContainer>
          <YAxisContainer>
            <Box
              component="img"
              src={line}
              alt={"line"}
              style={{
                zIndex: 0,
                opacity: 0.5,
              }}
            />
          </YAxisContainer>
          <Stack
            sx={{ position: "relative" }}
            justifyContent="space-between"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <Box
              component="img"
              src={alphaViewSrc}
              alt={"alpha angle view"}
              style={{
                transform: `rotate(${-alpha}deg)`,
                transformOrigin: "50% 20%",
                translate: `${Math.abs(alpha) > 90 ? "0 12rem" : 0}`,
                margin: "3.5rem 0",
                width: "100%",
              }}
            />
            <SmallData
              variant="h4"
              width="100%"
              align="center"
              mb={0.5}
              styles={{ bottom: 0, whiteSpace: "nowrap" }}
            >
              α = {degreesToString(alpha)}
            </SmallData>
          </Stack>
        </DeviceViewContainer>
        <DeviceViewContainer>
          <YAxisContainer>
            <Box
              component="img"
              src={line}
              alt={"line"}
              style={{
                zIndex: 0,
                opacity: 0.5,
              }}
            />
          </YAxisContainer>
          <Stack
            sx={{ position: "relative" }}
            justifyContent="space-between"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <Box
              component="img"
              src={betaViewSrc}
              alt={"beta angle view"}
              style={{
                transform: `rotate(${-beta}deg)`,
                transformOrigin: "50% 30%",
                translate: `${Math.abs(beta) > 90 ? "0 10rem" : 0}`,
                margin: "3rem 0",
                width: "100%",
              }}
            />
            <SmallData
              variant="h4"
              align="center"
              mb={0.5}
              styles={{
                color: theme.palette.secondary.main,
                bottom: 0,
                whiteSpace: "nowrap",
              }}
            >
              β = {degreesToString(beta)}
            </SmallData>
          </Stack>
        </DeviceViewContainer>
      </GridContainer>
    </PlotContainer>
  )
}

export default DevicePositionPlot
