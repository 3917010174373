import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Grid } from "@mui/material"
import fp from "lodash/fp"

import type { IGroupWState } from "types/group.types"

import useDeviceGroups from "helpers/hooks/useDeviceGroups"
import useDevices from "helpers/hooks/useDevices"
import Message from "widgets/common/Message"
import DeviceGroupsSkeleton from "widgets/skeletons/DeviceGroupsSkeleton"
import GroupCard from "./GroupCard"

const GroupCardsSection = () => {
  const {
    groupsWRelsByIdWStates,
    isError: groupsError,
    isLoading: groupsLoading,
    settingsIsLoading,
  } = useDeviceGroups()
  const { isLoading: devicesLoading } = useDevices()
  const { t } = useTranslation()

  const isLoading = useMemo(
    () => groupsLoading || settingsIsLoading || devicesLoading,
    [devicesLoading, groupsLoading, settingsIsLoading],
  )

  const sortedGroups = useMemo(
    () =>
      fp.flow(
        fp.values,
        fp.sortBy(({ group }) => group.name.toLowerCase()),
      )(groupsWRelsByIdWStates),
    [groupsWRelsByIdWStates],
  )
  return (
    <Grid container spacing={2} sx={{ pt: 0 }}>
      {isLoading ? (
        <Grid item xs={12}>
          <DeviceGroupsSkeleton />
        </Grid>
      ) : groupsError ? (
        <Message
          messageKey={"error.FETCHING_ITEM"}
          messageOptions={{
            item: t("device_groups.GROUPS").toLowerCase(),
          }}
          type="error"
        />
      ) : (
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="stretch">
            {sortedGroups.map(({ group, settingsStates }: IGroupWState) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={group.id}
                  data-testid="device-group-card"
                >
                  <GroupCard deviceGroup={group} settingsStates={settingsStates} />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default GroupCardsSection
