import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import type { CSSProperties } from "react"
import type { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables"

import type { Powerline } from "types/powerlines.types"

import GenericTable from "widgets/common/Table"
import SkeletonWrapper from "widgets/common/SkeletonWrapper"
import {
  buildPowerlinesSearch,
  formatPowerlinesTableCsv,
  getPowerlinesTableColumns,
  getPowerlinesTableRows,
} from "helpers/utils/powerlines"

import {
  selectPowerlinesTable,
  setPowerlinesTableRowsAmount,
  setPowerlinesTableSortOrder,
} from "features/store/tablesSlice"
import { getColsByName } from "helpers/utils/alerts"
import useRoutes from "helpers/hooks/useRoutes"
import { formatFilename } from "helpers/utils/common"
import { selectCurrentOrgName } from "features/store/orgSlice"

interface PowerlinesTableProps {
  powerlines: Powerline[]
  isPending: boolean
  titleKey?: string
  customColumns?: MUIDataTableColumn[]
  options?: MUIDataTableOptions
  styles?: CSSProperties
}

const PowerlinesTable = ({
  powerlines,
  isPending,
  titleKey,
  customColumns = [],
  options,
  styles = {},
}: PowerlinesTableProps) => {
  const { baseOrgURL } = useRoutes()
  const { t } = useTranslation()
  const powerlinesTable = useSelector(selectPowerlinesTable)
  const orgName = useSelector(selectCurrentOrgName)
  const dispatch = useDispatch()

  const columns: MUIDataTableColumn[] = useMemo(
    () =>
      customColumns.length > 0 ? customColumns : getPowerlinesTableColumns(baseOrgURL, t),
    [baseOrgURL, customColumns, t],
  )

  const { rows, columnsIndexes } = useMemo(() => {
    return {
      rows: getPowerlinesTableRows(powerlines),
      columnsIndexes: getColsByName(columns),
    }
  }, [powerlines, columns])

  return (
    <SkeletonWrapper isWaiting={isPending || !powerlines} height={540} width={"100vw"}>
      <GenericTable
        styles={styles}
        title={titleKey}
        rows={rows}
        columns={columns}
        options={{
          customSearch: buildPowerlinesSearch(columnsIndexes),
          downloadOptions: {
            filename: formatFilename(`${orgName}_${t("powerlines.POWERLINES")}`, "csv"),
          },
          onDownload: (buildHead, buildBody, columns, data) => {
            const formattedData = formatPowerlinesTableCsv(data, columnsIndexes)
            return `${buildHead(columns)}${buildBody(formattedData)}`.trim()
          },
          /* --- Persistence values --- */
          rowsPerPage: powerlinesTable?.qtyRows,
          sortOrder: powerlinesTable?.sortOrder,
          onChangeRowsPerPage: (numberOfRows) => {
            dispatch(setPowerlinesTableRowsAmount(numberOfRows))
          },
          onColumnSortChange: (changedColumn, direction) => {
            dispatch(setPowerlinesTableSortOrder({ name: changedColumn, direction }))
          },
          ...options,
        }}
      />
    </SkeletonWrapper>
  )
}

export default PowerlinesTable
