import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useRemoveDevicesFromGroupMutation } from "features/api"
import { selectCurrentOrgId, selectCurrentOrgName } from "features/store/orgSlice"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"

import type { DeviceWGroupsNStates } from "types/device.types"
import type { DeviceGroup } from "types/group.types"

import usePermissions from "helpers/hooks/usePermissions"
import { formatFilename } from "helpers/utils/common"
import RemoveDevicePopup from "widgets/deviceGroups/editor/RemoveDevicePopup"
import DevicesTableToolbar from "./DevicesTableToolbar"
import DevicesTableToolbarSelect from "./DevicesTableToolbarSelect"
import DevicesTable from "."

interface DeviceGroupDevicesTableProps {
  devices: DeviceWGroupsNStates[]
  isLoading: boolean
  isFetching: boolean
  titleKey: string
  group: DeviceGroup
}

function DeviceGroupDevicesTable({
  devices,
  isLoading,
  isFetching,
  titleKey,
  group,
}: DeviceGroupDevicesTableProps) {
  const { t } = useTranslation()
  const { isEditor } = usePermissions()
  const orgId = useSelector(selectCurrentOrgId)
  const orgName = useSelector(selectCurrentOrgName)
  const [removeDevicesFromGroup, { isLoading: isRemoving }] =
    useRemoveDevicesFromGroupMutation()
  const [selectedDevices, setSelectedDevices] = useState<DeviceWGroupsNStates[]>([])
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [openPopup, setOpenPopup] = useState<boolean>(false)

  const handleDelete = useCallback(
    async (deviceIds) =>
      snackbarMutation({
        mutation: removeDevicesFromGroup({
          lessIds: deviceIds,
          groupId: group.id,
          orgId: Number(orgId),
        }).unwrap(),
        getErrorMessage: buildGetErrorMessage(
          t("error.DELETING_ITEM", { item: t("generic.DEVICES") }),
        ),
        getSuccessMessage: () =>
          t("success.REMOVING_ITEM", {
            item: t("generic.DEVICES"),
            count: 2,
            context: "male",
          }),
      }),
    [group.id, orgId, removeDevicesFromGroup, t],
  )

  const handleOpen = (selectedDevices: DeviceWGroupsNStates[]) => {
    setOpenPopup(true)
    setSelectedDevices(selectedDevices)
  }

  const handleClose = () => {
    setOpenPopup(false)
    setSelectedRows([])
  }

  const onConfirmRemove = useCallback(() => {
    const lessIds = selectedDevices.map((selectedDevice) => selectedDevice.device.less_id)
    handleDelete(lessIds)
      .then(() => handleClose())
      .catch()
  }, [handleDelete, selectedDevices])

  const handleRowSelectionChange = (rowsSelected: number[]) =>
    setSelectedRows(rowsSelected)

  return (
    <>
      <DevicesTable
        titleKey={titleKey}
        devices={devices}
        isLoading={isLoading}
        isFetching={isFetching}
        options={{
          rowsSelected: selectedRows,
          selectableRows: isEditor ? "multiple" : "none",
          downloadOptions: {
            filename: formatFilename(
              `${orgName}_${group.name}_${t("generic.DEVICES")}`,
              "csv",
            ),
          },
          customToolbarSelect: (selectedRows: {
            data: { index: number; dataIndex: number }[]
          }) => {
            const selectedDevices = selectedRows.data
              ?.map((selectedRow) => devices[selectedRow.dataIndex])
              .filter((device) => device)
            return (
              <DevicesTableToolbarSelect handleOpen={() => handleOpen(selectedDevices)} />
            )
          },
          customToolbar: () => isEditor && <DevicesTableToolbar devices={devices} />,
          onRowSelectionChange: (
            _currentRowsSelected,
            _allRowsSelected,
            rowsSelected,
          ) => {
            rowsSelected && handleRowSelectionChange(rowsSelected)
          },
        }}
      />
      <RemoveDevicePopup
        isLoading={isRemoving}
        selectedDevices={selectedDevices}
        deviceGroup={group}
        open={openPopup}
        onClose={handleClose}
        onConfirm={onConfirmRemove}
      />
    </>
  )
}

export default DeviceGroupDevicesTable
