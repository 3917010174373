import { Grid } from "@mui/material"
import fp from "lodash/fp"
import PieChartSkeleton from "widgets/skeletons/PieChartSkeleton"

const HomePieChartsSkeleton = () => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        {fp
          .times((index) => index)(2)
          .map((chart) => {
            return (
              <Grid key={chart} item xs={12} sm={12} md={6} lg={6} xl={6}>
                <PieChartSkeleton />
              </Grid>
            )
          })}
      </Grid>
    </Grid>
  )
}

export default HomePieChartsSkeleton
