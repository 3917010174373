import type { DeviceSettings } from "types/device.types"
import type { Geolocation } from "types/geolocation.types"
import type { Powerline } from "types/powerlines.types"
import type { Span } from "types/spans.types"
import type { Tower } from "types/towers.types"

export interface InstallationDetailsForm {
  installationType: string
  orientation: string
  anchoringPointDistance: number | ""
  voltageNominal: number | ""
  powerlineId: number | ""
  spanId: number | ""
  phaseId: string
  towerOneId: number | ""
  towerOneHeight: number | ""
  towerOneGeolocation: string
  towerTwoId: number | ""
  towerTwoHeight: number | ""
  towerTwoGeolocation: string
}

const formatGeolocation = (
  geolocation: Geolocation | undefined | null,
): string | undefined => {
  let result: string | undefined = undefined
  if (geolocation) {
    result = `${geolocation.latitude}, ${geolocation.longitude}`
    if (geolocation.altitude) result += `, ${geolocation.altitude}`
  }
  return result
}

export const getTowerTwoId = (
  towerOneId: number | undefined,
  spanTowerIds: number[] | undefined,
): number | undefined => {
  if (towerOneId && spanTowerIds) {
    return spanTowerIds[0] === towerOneId ? spanTowerIds[1] : spanTowerIds[0]
  }
  return
}

export const getDefaultValues = (
  deviceSettings: DeviceSettings | null | undefined,
  powerline: Powerline | undefined,
  span: Span | undefined,
  towerOne: Tower | undefined,
  towerTwo: Tower | undefined,
): InstallationDetailsForm => ({
  installationType: deviceSettings?.installation_type ?? "",
  orientation: deviceSettings?.orientation ?? "",
  anchoringPointDistance: deviceSettings?.lpc_distance ?? "",
  voltageNominal: powerline?.voltage_nominal ?? "",
  powerlineId: deviceSettings?.powerline_id ?? "",
  spanId: deviceSettings?.span_id ?? "",
  phaseId: span?.phase_id ?? "",
  towerOneId: towerOne?.id ?? "",
  towerOneHeight: towerOne?.height ?? "",
  towerOneGeolocation: formatGeolocation(towerOne?.geo_location) ?? "",
  towerTwoId: towerTwo?.id ?? "",
  towerTwoHeight: towerTwo?.height ?? "",
  towerTwoGeolocation: formatGeolocation(towerTwo?.geo_location) ?? "",
})
