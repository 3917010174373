import fp from "lodash/fp"

import type {
  DeviceWGroupsNStates,
  IDevsWRelsByIdWStates,
  ILessIdByDeviceId,
} from "types/device.types"

export const getDevicesByGroupList = (
  devices: IDevsWRelsByIdWStates,
  lessIdByDeviceId: ILessIdByDeviceId,
  deviceList: number[],
): DeviceWGroupsNStates[] => {
  return fp.flow(
    fp.map((deviceId: number) => devices[lessIdByDeviceId[deviceId]]),
    fp.filter((deviceData) => deviceData !== undefined),
  )(deviceList)
}
