import type { FC } from "react"
import { useCallback, useMemo, useState } from "react"
import Dialog from "@mui/material/Dialog"
import Button from "@mui/material/Button"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import type { Theme } from "@mui/material"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import fp from "lodash/fp"

import { selectCurrentOrgId } from "../../../features/store/orgSlice"
import { useAddDeviceToGroupMutation } from "../../../features/api"
import LoadingButton from "../../common/LoadingButton"
import Spinner from "../../common/Spinner"
import useDevices from "../../../helpers/hooks/useDevices"
import type { DeviceWGroupsNStates } from "../../../types/device.types"
import AddDevicesToGroupTable from "./AddDevicesToGroupTable"

interface IAddDevicesToGroupPopupProps {
  open: boolean
  onClose: () => void
  groupDevices: DeviceWGroupsNStates[]
}

const AddDevicesToGroupPopup: FC<IAddDevicesToGroupPopupProps> = ({
  onClose,
  open,
  groupDevices,
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const orgId = useSelector(selectCurrentOrgId)
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<number[]>([])
  const [rowsSelected, setRowsSelected] = useState<Array<number> | undefined>()
  const {
    devsWRelsById,
    isFetching: devicesFetching,
    isLoading: devicesLoading,
  } = useDevices()
  const devicesFirstFetch = useMemo(
    () => devicesFetching && fp.isEmpty(devsWRelsById),
    [devsWRelsById, devicesFetching],
  )

  const devicesToAdd = useMemo(() => {
    const groupDevicesIds = fp.map((groupDevices: DeviceWGroupsNStates) =>
      groupDevices.device.less_id.toString(),
    )(groupDevices)
    const devicesNotInGroup = fp.values(
      fp.pickBy((_value, key) => !groupDevicesIds.includes(key), devsWRelsById),
    )
    return devicesNotInGroup
  }, [devsWRelsById, groupDevices])

  const [addDeviceToGroup, { isLoading: addDeviceIsLoading }] =
    useAddDeviceToGroupMutation()

  const handleSelect = useCallback(
    (devicesIds: number[], dataIndexes: number[]) => {
      setSelectedDeviceIds(devicesIds)
      dataIndexes?.length ? setRowsSelected(dataIndexes) : setRowsSelected(undefined)
    },
    [setSelectedDeviceIds, setRowsSelected],
  )
  const addButtonDisabled = useMemo(
    () => !(selectedDeviceIds && selectedDeviceIds.length > 0),
    [selectedDeviceIds],
  )

  const handleAddDevices = useCallback(async () => {
    if (selectedDeviceIds && selectedDeviceIds.length > 0) {
      for (const selectedDeviceId of selectedDeviceIds) {
        await addDeviceToGroup({
          less_id: selectedDeviceId,
          org_id: orgId as number,
          group_id: id as string,
        })
      }
      onClose()
    }
  }, [selectedDeviceIds, addDeviceToGroup, orgId, id, onClose])

  return (
    <>
      <Dialog open={open} scroll={"paper"} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>{t("device_groups.ADD_DEVICE")}</DialogTitle>
        {devicesFirstFetch || devicesLoading ? (
          <Box
            sx={{
              minWidth: "270px",
              minHeight: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </Box>
        ) : (
          <>
            <DialogContent
              dividers={true}
              sx={{
                overflowY: "unset",
                padding: 0,
              }}
            >
              {devicesToAdd?.length > 0 ? (
                <AddDevicesToGroupTable
                  devices={devicesToAdd}
                  handleSelect={handleSelect}
                  isLoading={devicesLoading}
                  rowsSelected={rowsSelected}
                />
              ) : (
                <Typography>{t("device.NO_DEVICES_ASSIGN")}</Typography>
              )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "flex-end" }}>
              <Button
                onClick={onClose}
                sx={{
                  color: (theme: Theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.grey[300]
                      : theme.palette.grey[600],
                }}
              >
                {t("generic.CANCEL")}
              </Button>
              <LoadingButton
                styles={{ width: "fit-content" }}
                loading={addDeviceIsLoading}
                onClick={handleAddDevices}
                disabled={addButtonDisabled}
                type="button"
              >
                {t("generic.ADD")}
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  )
}

export default AddDevicesToGroupPopup
