import { type FormEventHandler, useEffect, useState, useMemo } from "react"
import { useTranslation } from "react-i18next"
import type { UseFormReturn } from "react-hook-form"
import { FormProvider } from "react-hook-form"
import { Box, Grid, Stack, Typography } from "@mui/material"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import type { SerializedError } from "@reduxjs/toolkit"

import type { ICustomError } from "helpers/errors/errorTypes"
import { getTypeParams } from "helpers/formatters/alertSettingsFormatters"
import {
  type AlertSettingParam,
  type AlertSettingType,
  type AlertSettingUpsertForm,
} from "types/alerts.types"
import { SectionCard } from "widgets/styled/containers"
import Spinner from "widgets/common/Spinner"
import ControlledInput from "widgets/common/ControlledInput"
import ErrorPopUp from "widgets/common/ErrorPopUp"
import FormButtons from "widgets/common/FormButtons"
import AlertSettingInput from "widgets/alerts/settings/AlertSettingInput"

interface AlertSettingFormProps {
  isLoading: boolean
  title: string
  onSubmit: FormEventHandler<HTMLFormElement>
  methods: UseFormReturn<AlertSettingUpsertForm, any>
  types: AlertSettingType[]
  selectedTypeName: string
  onClose: () => void
  buttonText: string
  errorText: string
  disableType?: boolean
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined | unknown
  isSuccess: boolean
}

function AlertSettingForm({
  isLoading,
  title,
  onSubmit,
  methods,
  types,
  selectedTypeName,
  onClose,
  buttonText,
  errorText,
  disableType,
  isError,
  error,
  isSuccess,
}: AlertSettingFormProps) {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [showErrorPopup, setShowErrorPopup] = useState(false)
  const typesList = useMemo(
    () =>
      types?.map((type) => ({
        label: type.title,
        value: type.name,
      })),
    [types],
  )
  const { selectedType, typeParams } = useMemo(() => {
    const selectedType = types?.find((type) => type.name === selectedTypeName)
    const typeParams = getTypeParams(selectedTypeName, types, ["active", "send_sms"])
    return { selectedType, typeParams }
  }, [selectedTypeName, types])

  useEffect(() => {
    if (isError) {
      setShowErrorPopup(true)
      const newError = error as ICustomError
      setErrorMessage(newError.data.message)
    }
    if (isSuccess) {
      onClose()
    }
  }, [isError, error, isSuccess, onClose])

  return (
    <>
      <SectionCard
        sx={{
          padding: "2rem",
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <Stack spacing={2}>
            <Typography component="h5" variant="h5">
              {t(title)}
            </Typography>
            <form onSubmit={onSubmit}>
              <FormProvider {...methods}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <ControlledInput
                      type="select"
                      placeholder=""
                      name="type"
                      label={t("alerts.ALERT_TYPE")}
                      selectItems={typesList}
                      variant="outlined"
                      disabled={disableType}
                      defaultValue=""
                    />
                    {selectedTypeName?.length > 0 && selectedType && (
                      <Typography
                        component="p"
                        sx={{ color: (theme) => theme.palette.text.secondary }}
                      >
                        {selectedType.description}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {selectedTypeName?.length > 0 && selectedType && (
                      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {typeParams?.map((param: AlertSettingParam) => (
                          <AlertSettingInput param={param} key={param.id} />
                        ))}
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <FormButtons
                  onClose={onClose}
                  disabled={!(selectedTypeName?.length > 0 && selectedType)}
                  buttonText={buttonText}
                />
              </FormProvider>
            </form>
          </Stack>
        )}
      </SectionCard>
      <ErrorPopUp open={showErrorPopup} onClose={() => setShowErrorPopup(false)}>
        {errorMessage ? errorMessage : t(errorText)}
      </ErrorPopUp>
    </>
  )
}

export default AlertSettingForm
