import { useSelector } from "react-redux"

import { selectCurrentOrgId } from "features/store/orgSlice"
import { usePowerlineGeoQuery } from "features/api"

interface usePowerlineGeoProps {
  id: number | undefined
}

const usePowerlineGeo = ({ id }: usePowerlineGeoProps) => {
  const orgId = useSelector(selectCurrentOrgId)

  const {
    data: powerlineGeoData,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = usePowerlineGeoQuery(
    {
      id: Number(id),
      params: { org_id: Number(orgId) },
    },
    {
      skip: !orgId || !id,
    },
  )

  return {
    powerlineGeoData,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export default usePowerlineGeo
