import { type FC } from "react"

import type { DeviceLocation } from "types/geolocation.types"

import useAlertSettings from "helpers/hooks/useAlertSettings"
import { getDevicePosition, getMarkerIcon } from "helpers/utils/map"
import { getDeviceVersionAndTarget } from "helpers/utils/devices"
import { hasDeviceTriggeredAlerts } from "../../../helpers/utils/alerts"
import GeoPopup from "./GeoPopup"
import MapMarker from "./MapMarker"

interface IDeviceMapMarkersProps {
  devices: DeviceLocation[]
}

const DeviceMapMarkers: FC<IDeviceMapMarkersProps> = ({ devices }) => {
  const { orgAlertsBySettingId } = useAlertSettings()

  return (
    <>
      {devices?.map((deviceLocation: DeviceLocation) => {
        const { device, settingsStates } = deviceLocation
        const triggered =
          !!settingsStates &&
          hasDeviceTriggeredAlerts(settingsStates, orgAlertsBySettingId)
        const { version, target } = getDeviceVersionAndTarget(device)

        const icon = getMarkerIcon(triggered, version, target)
        const position = getDevicePosition(deviceLocation)

        return (
          <MapMarker
            key={device.id}
            position={position}
            icon={icon}
            zIndexOffset={triggered ? 500 : 100}
            popup={<GeoPopup type="device" data={deviceLocation} />}
            tooltipContent={device.name}
          />
        )
      })}
    </>
  )
}

export default DeviceMapMarkers
