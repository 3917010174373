import type { FC } from "react"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FormProvider, useForm } from "react-hook-form"
import { Box, Grid, Stack, Typography } from "@mui/material"
import fp from "lodash/fp"

import FormButtons from "widgets/common/FormButtons"
import type { Language, SelectOptionType } from "types/common.types"
import ControlledInput from "widgets/common/ControlledInput"
import { LANGUAGES } from "helpers/utils/constants"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import type { OrgType } from "../../types/orgs.types"
import { useUpdateOrgSettingsMutation } from "../../features/api"
import { toSentenceCase } from "../../helpers/utils/translations"
import EmailsForm from "./EmailsForm"
// import TelephonesForm from "./TelephonesForm"

interface NotificationSettingsProps {
  org: OrgType
  onClose: () => void
}

type NotificationSettingsFormT = {
  emails: { email: string }[]
  language: Language
}

const NotificationSettingsForm: FC<NotificationSettingsProps> = ({ org, onClose }) => {
  const { t } = useTranslation()
  const [updateOrgSettings, { isLoading }] = useUpdateOrgSettingsMutation()

  const defaultValues = useMemo(() => {
    const emails =
      org.email_list?.map((email) => ({
        email,
      })) || []
    const language = org.language
    return { emails, language }
  }, [org.email_list, org.language])

  const languageItems: SelectOptionType[] = useMemo(
    () =>
      fp.flow(
        fp.toPairs,
        fp.map(([key, value]: [string, { name: string }]) => ({
          label: value.name,
          value: key,
        })),
      )(LANGUAGES),
    [],
  )

  const methods = useForm<NotificationSettingsFormT>({
    mode: "all",
    defaultValues,
  })

  const onSubmit = async (data: NotificationSettingsFormT) => {
    const emails = data.emails.map((email) => email.email).join(", ")

    await snackbarMutation({
      mutation: updateOrgSettings({
        emails,
        language: data.language,
        orgId: org.id,
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.UPDATING_ITEM", {
          item: toSentenceCase(t("device_settings.NOTIFICATION_SETTINGS")),
        }),
      ),
      getSuccessMessage: () =>
        t("success.UPDATING_ITEM", {
          item: toSentenceCase(t("device_settings.NOTIFICATION_SETTINGS")),
          context: "female",
          count: 1,
        }),
    })
      .then(() => {
        onClose()
      })
      .catch(() => {})
  }

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues, methods])

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} style={{ height: "100%" }}>
      <FormProvider {...methods}>
        <Stack height={"100%"} justifyContent={"space-between"}>
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Typography variant="h5" component="h5" sx={{ pb: 0.5 }}>
                  {t("organization.LANGUAGE")}
                </Typography>
              </Box>
              <Stack sx={{ maxWidth: "120px", marginBottom: -2 }}>
                <ControlledInput
                  type="select"
                  name="language"
                  selectItems={languageItems}
                  size="small"
                  variant="outlined"
                />
              </Stack>
            </Grid>
            {/* <Grid item xs={12}>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Typography variant="h5" component="h5" sx={{ pt: 1, pb: 0.5 }}>
                  {t("enterprise.TELEPHONES")}
                </Typography>
              </Box>
              <TelephonesForm telephones={org.telephone_list} />
            </Grid> */}
            <Grid item xs={12}>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Typography variant="h5" component="h5" sx={{ pt: 1, pb: 0.5 }}>
                  {t("enterprise.EMAILS")}
                </Typography>
              </Box>
              <EmailsForm emails={org.email_list} />
            </Grid>
          </Grid>
          <FormButtons
            onClose={onClose}
            buttonText="generic.UPDATE"
            isLoading={isLoading}
          />
        </Stack>
      </FormProvider>
    </form>
  )
}

export default NotificationSettingsForm
