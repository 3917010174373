import { useTranslation } from "react-i18next"
import type { FC } from "react"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import fp from "lodash/fp"

import { Box } from "@mui/material"
import { Popup } from "widgets/styled/map"
import { featureProperties } from "helpers/utils/map"
import type { PowerlineFeatureProps } from "types/geolocation.types"
import { formatDecimalValues } from "helpers/utils/common"
import CustomLink from "widgets/common/CustomLink"
import useRoutes from "helpers/hooks/useRoutes"

interface PowerlinePopupProps {
  data: PowerlineFeatureProps
}

const PowerlinePopup: FC<PowerlinePopupProps> = ({ data }) => {
  const { i18n, t } = useTranslation()
  const { baseOrgURL } = useRoutes()
  const locale = i18n.resolvedLanguage

  return (
    <Popup>
      <Stack spacing={0}>
        {featureProperties.Powerline.map((property: string, i) => {
          const propertyValue = data[property as keyof PowerlineFeatureProps]
          if (!fp.isNil(propertyValue)) {
            const formattedProperty =
              typeof propertyValue === "number"
                ? formatDecimalValues(propertyValue, locale)
                : propertyValue
            return (
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                key={`PowerlineProperty-${data.id}-${i}`}
              >
                <Typography fontWeight="600">
                  {t(`geo_info.${property.toUpperCase()}`)}
                </Typography>
                <Typography variant="body2">{formattedProperty}</Typography>
              </Stack>
            )
          }
        })}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          margin="0.2rem 0"
        >
          <CustomLink href={`${baseOrgURL}/powerlines/${data.id}`} bold>
            {t("geo_info.GO_TO_POWERLINE")}
          </CustomLink>
        </Box>
      </Stack>
    </Popup>
  )
}

export default PowerlinePopup
