import type { Dispatch, SetStateAction } from "react"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import { Switch } from "@mui/material"

import { Card } from "widgets/styled/containers"
import {
  getTypeParams,
  getUiConfigValue,
} from "helpers/formatters/alertSettingsFormatters"
import DeleteAlertSettingPopup from "widgets/alerts/settings/DeleteAlertSettingPopup"
import AlertSettingCardMenu from "widgets/alerts/settings/AlertSettingCardMenu"
import { useUpdateDeviceAlertSettingMutation } from "features/api"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import usePermissions from "helpers/hooks/usePermissions"
import type { AlertSetting, AlertSettingType } from "types/alerts.types"

interface AlertSettingCardProps {
  alertSetting: AlertSetting
  alertTypes: AlertSettingType[]
  onOpen: () => void
  setSelectedAlert: Dispatch<SetStateAction<AlertSetting | undefined>>
}

export default function AlertSettingCard({
  alertSetting,
  alertTypes,
  onOpen,
  setSelectedAlert,
}: AlertSettingCardProps) {
  const { t } = useTranslation()
  const typeParams = useMemo(
    () =>
      getTypeParams(alertSetting.type, alertTypes, ["active", "custom_name", "send_sms"]),
    [alertSetting.type, alertTypes],
  )
  const { isEditor } = usePermissions()
  const [openDeletePopup, setOpenDeletePopup] = useState(false)
  const [checked, setChecked] = useState(Boolean(alertSetting.config.active))
  const [updateDeviceAlertSetting, { isLoading }] = useUpdateDeviceAlertSettingMutation()

  const handleOpenDeletePopup = () => {
    setOpenDeletePopup(true)
  }
  const handleCloseDeletePopup = () => {
    setOpenDeletePopup(false)
  }

  const handleActiveSetting = () => {
    setChecked(!checked)
    snackbarMutation({
      mutation: updateDeviceAlertSetting({
        ...alertSetting,
        config: {
          ...alertSetting.config,
          active: !alertSetting.config.active,
        },
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(t("alerts.ALERT_UPDATED_ERROR")),
      getSuccessMessage: () => t("alerts.ALERT_UPDATED_SUCCESS"),
    }).catch()
  }

  return (
    <>
      <Card
        elevation={3}
        styles={{
          height: "100%",
          paddingTop: "0.5rem",
        }}
      >
        <CardContent>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={"1rem"}
          >
            <Typography variant="h5" noWrap>
              {alertSetting.config.custom_name}
            </Typography>
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              {isEditor && (
                <>
                  <Switch
                    size="small"
                    checked={checked}
                    onChange={handleActiveSetting}
                    disabled={isLoading}
                  />
                  <AlertSettingCardMenu
                    alertSetting={alertSetting}
                    setSelectedAlert={setSelectedAlert}
                    onOpen={onOpen}
                    handleOpenDeletePopup={handleOpenDeletePopup}
                  />
                </>
              )}
            </Stack>
          </Stack>
          <Stack spacing={2} divider={<Divider flexItem />}>
            {typeParams?.map((typeParam, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <Typography>{typeParam.name}:</Typography>
                  <Typography align="right" color="text.secondary">
                    {getUiConfigValue(typeParam, alertSetting)}
                  </Typography>
                </Box>
              )
            })}
          </Stack>
        </CardContent>
      </Card>
      {openDeletePopup && (
        <DeleteAlertSettingPopup
          open={openDeletePopup}
          onClose={handleCloseDeletePopup}
          alertSetting={alertSetting}
        />
      )}
    </>
  )
}
