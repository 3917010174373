import type { FC, PropsWithChildren } from "react"
import { MapContainer, ScaleControl, TileLayer } from "react-leaflet"
import type { LatLngTuple } from "leaflet"

import { OSM_TILE_SERVER_CONFIG } from "features/config"
import {
  MAP_HEIGHT,
  DEFAULT_MAP_CENTER,
  TOOLTIP_THRESHOLD,
} from "helpers/utils/constants"
import { showTooltips } from "helpers/utils/map"

import "./map.css"

interface MapProps {
  center?: LatLngTuple | null
  mapHeight?: string
  initialZoomLevel?: number
}

const Map: FC<PropsWithChildren<MapProps>> = ({
  center,
  mapHeight = MAP_HEIGHT,
  initialZoomLevel = 9,
  children,
}) => {
  return (
    <>
      <MapContainer
        center={center ?? DEFAULT_MAP_CENTER}
        zoom={center ? initialZoomLevel : 2}
        scrollWheelZoom={true}
        style={{ height: mapHeight }}
        whenCreated={(mapInstance) => {
          const zoom = mapInstance.getZoom()
          showTooltips(mapInstance, zoom >= TOOLTIP_THRESHOLD)
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={OSM_TILE_SERVER_CONFIG}
        />
        {children}
        <ScaleControl position="bottomleft" />
      </MapContainer>
    </>
  )
}

export default Map
