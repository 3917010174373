import { useTranslation } from "react-i18next"

import type { IPlotView, RecordType } from "types/dashboard.types"

import Message from "widgets/common/Message"
import Plot from "widgets/plots/Plot"

interface OrgPieChartProps {
  view: IPlotView
  data: RecordType[]
  isError: boolean
}

const OrgPieChart = ({ view, data, isError }: OrgPieChartProps) => {
  const { t } = useTranslation()

  return (
    <>
      {isError ? (
        <Message
          messageKey={"error.FETCHING_ITEM"}
          messageOptions={{ item: t("alerts.CHART") }}
          type="error"
        />
      ) : (
        <Plot view={view} data={data} />
      )}
    </>
  )
}

export default OrgPieChart
