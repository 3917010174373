import { useOutletContext } from "react-router-dom"
import Grid from "@mui/material/Grid"
import fp from "lodash/fp"

import { useMemo } from "react"

import { BodyContainer } from "widgets/styled/containers"
import MapSection from "widgets/common/MapSection"
import GeoJSONLayers from "widgets/plots/geo/GeoJSONLayers"
import type { Powerline } from "types/powerlines.types"
import usePowerlineGeo from "helpers/hooks/powerlines/usePowerlineGeo"
import useDevices from "helpers/hooks/useDevices"
import { getMapCenterFromDeviceGeo } from "helpers/utils/map"
import { getDevicesByPowerlineId } from "helpers/utils/powerlines"
import DevicesTable from "widgets/device/DevicesTable"

const PowerlineInfo = () => {
  const { powerline, powerlineFetching } = useOutletContext<{
    powerline: Powerline
    powerlineFetching: boolean
  }>()
  const { powerlineGeoData, isLoading: geoLoading } = usePowerlineGeo({
    id: powerline.id,
  })
  const {
    devsWRelsByIdWStates,
    isFetching: devicesFetching,
    isLoading: devicesLoading,
  } = useDevices()

  const shouldRenderMap = useMemo(
    () =>
      powerlineGeoData?.Powerline?.features &&
      powerlineGeoData.Powerline.features.length > 0,
    [powerlineGeoData],
  )

  const center = useMemo(
    () => getMapCenterFromDeviceGeo(powerlineGeoData),
    [powerlineGeoData],
  )

  const devices = useMemo(
    () =>
      devsWRelsByIdWStates
        ? getDevicesByPowerlineId(devsWRelsByIdWStates, powerline.id)
        : [],
    [devsWRelsByIdWStates, powerline],
  )

  const devicesFirstFetch = useMemo(
    () => devicesFetching && fp.isEmpty(devsWRelsByIdWStates),
    [devsWRelsByIdWStates, devicesFetching],
  )

  return (
    <BodyContainer disableGutters maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MapSection
            center={center}
            isPending={powerlineFetching || geoLoading || !powerlineGeoData}
            shouldRender={!!shouldRenderMap}
          >
            <GeoJSONLayers data={powerlineGeoData} />
          </MapSection>
        </Grid>
        <Grid item xs={12}>
          <DevicesTable
            devices={devices}
            isFetching={devicesFirstFetch}
            isLoading={devicesLoading || powerlineFetching}
            titleKey="powerlines.POWERLINE_DEVICES"
          />
        </Grid>
      </Grid>
    </BodyContainer>
  )
}

export default PowerlineInfo
