import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FormProvider, useForm } from "react-hook-form"
import { Stack, Typography } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import fp from "lodash/fp"

import type {
  AuthMechanism,
  AuthMechanismKey,
  OAuthMicrosoftWithTenantAuthMechanism,
  OrgType,
} from "types/orgs.types"

import CardWithTitle from "widgets/common/CardWithTitle"
import ControlledInput from "widgets/common/ControlledInput"
import FormButtons from "widgets/common/FormButtons"
import { useUpdateOrgAuthMechanismsMutation } from "features/api"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import {
  allAuthMechanisms,
  authMechanismsTranslations,
  getDefaultValues,
} from "./helpers"
import TenantsForm from "./TenantsForm"
import TenantsList from "./TenantsList"

interface IAuthMechanismsForm {
  Password: boolean
  "2FA": boolean
  OAuth: boolean
  OAuthGoogle: boolean
  OAuthMicrosoft: boolean
  OAuthMicrosoftWithTenant: boolean
  tenants: { tenant: string }[]
}

interface AuthenticationSettingsProps {
  org: OrgType
}

const AuthenticationSettings = ({ org }: AuthenticationSettingsProps) => {
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useState(false)

  const { tenants, defaultValues } = useMemo(() => {
    const authMechanismsTypes = org.auth_mechanisms.map((value) => value.type)

    const tenants = fp.flow(
      fp.filter(
        (authMech: AuthMechanism) => authMech.type === "OAuthMicrosoftWithTenant",
      ),
      fp.map((authMech: OAuthMicrosoftWithTenantAuthMechanism) => authMech.tid),
    )(org.auth_mechanisms)

    const defaultValues = getDefaultValues(authMechanismsTypes, tenants)

    return { tenants, defaultValues }
  }, [org.auth_mechanisms])

  const methods = useForm<IAuthMechanismsForm>({
    mode: "all",
    defaultValues,
  })

  const handleClose = () => {
    methods.reset()
    setIsEditing(false)
  }
  const handleEditClick = () => {
    setIsEditing(true)
  }

  const [updateOrgAuthMechanisms, { isLoading: isUpdating }] =
    useUpdateOrgAuthMechanismsMutation()

  const onSubmit = async (data: IAuthMechanismsForm) => {
    const commonAuthMechanisms = fp.flow(
      fp.omitBy((_value, key) => ["OAuthMicrosoftWithTenant", "tenants"].includes(key)),
      fp.toPairs,
      fp.filter(([_key, value]) => value),
      fp.map(([key, _value]) => ({ type: key })),
    )(data) as AuthMechanism[]

    const microsoftWithTenantAuthMechanisms = data.OAuthMicrosoftWithTenant
      ? data?.tenants?.map((tid) => ({
          type: "OAuthMicrosoftWithTenant" as const,
          tid: tid.tenant,
        }))
      : []

    const auth_mechanisms = [
      ...commonAuthMechanisms,
      ...microsoftWithTenantAuthMechanisms,
    ]

    await snackbarMutation({
      mutation: updateOrgAuthMechanisms({
        orgId: org.id,
        auth_mechanisms,
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.UPDATING_ITEM", { item: t("organization.AUTHENTICATION_SETTINGS") }),
      ),
      getSuccessMessage: () =>
        t("success.UPDATING_ITEM", {
          item: t("organization.AUTHENTICATION_SETTINGS"),
          context: "female",
          count: 1,
        }),
    })
      .then(() => {
        methods.reset()
        handleClose()
      })
      .catch()
  }

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues, methods])

  return (
    <CardWithTitle
      titleKey={"organization.AUTHENTICATION_SETTINGS"}
      isDoingAction={isEditing}
      handleAction={handleEditClick}
      actionIcon={<EditIcon fontSize="small" />}
    >
      <Typography mb={2}>{t("auth.SET_ORG_AUTHENTICATION")}</Typography>
      <form onSubmit={methods.handleSubmit(onSubmit)} style={{ height: "100%" }}>
        <FormProvider {...methods}>
          <Stack height={"100%"} justifyContent={"space-between"}>
            <Stack>
              {allAuthMechanisms.map((authMechanism: AuthMechanismKey) => {
                return (
                  <ControlledInput
                    key={authMechanism}
                    type="checkbox"
                    name={authMechanism}
                    label={t(
                      `auth_mechanisms.${authMechanismsTranslations[authMechanism]}`,
                    )}
                    disabled={!isEditing}
                    defaultChecked={defaultValues[authMechanism]}
                  />
                )
              })}
              {!isEditing && Boolean(defaultValues.tenants?.length) && (
                <TenantsList tenants={tenants} />
              )}
              {methods.watch("OAuthMicrosoftWithTenant") && isEditing && <TenantsForm />}
              <Typography fontStyle={"italic"} mt={3}>
                * {t("auth.SET_ORG_AUTHENTICATION_CLARIFICATION")}
              </Typography>
            </Stack>
            {isEditing && (
              <FormButtons
                onClose={handleClose}
                buttonText={"generic.UPDATE"}
                isLoading={isUpdating}
              />
            )}
          </Stack>
        </FormProvider>
      </form>
    </CardWithTitle>
  )
}

export default AuthenticationSettings
