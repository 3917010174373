import type { FC } from "react"
import Dialog from "@mui/material/Dialog"
import Button from "@mui/material/Button"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import type { Theme } from "@mui/material"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { FormProvider, useForm } from "react-hook-form"
import fp from "lodash/fp"

import {
  useActivateDeviceMutation,
  useCreateDeviceMutation,
  useDeleteDeviceMutation,
  useDeviceGroupsQuery,
  useSellDeviceMutation,
} from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"
import { DEVICE_TYPES, NEW_DEVICE_BACKEND } from "helpers/utils/constants"
import { snackbarMutation } from "helpers/utils/mutations"
import { addEndPeriodIfMissing } from "helpers/utils/translations"
import ControlledInput from "widgets/common/ControlledInput"
import LoadingButton from "widgets/common/LoadingButton"

import type { SelectOptionType } from "types/common.types"
import type { DeviceGroup } from "types/group.types"
import type { SellDeviceRes } from "types/device.types"

interface ICreateDevicePopupProps {
  open: boolean
  onClose: () => void
}

interface ICreateDeviceForm {
  lessId: string
  deviceName: string
  deviceType: string
  groupId: string
  orgId: number
}

const CreateDevicePopup: FC<ICreateDevicePopupProps> = ({ onClose, open }) => {
  const { t } = useTranslation()
  const orgId = useSelector(selectCurrentOrgId)
  const deviceTypesOptions: SelectOptionType[] = fp.flow(
    fp.map((deviceType: string) => ({
      label: t(`device_types.${deviceType.toUpperCase()}`),
      value: deviceType,
    })),
  )(DEVICE_TYPES)
  const { currentData: groups } = useDeviceGroupsQuery({
    params: { describe_devices: false, org_id: orgId as number },
  })
  const deviceGroupsOptions: SelectOptionType[] = fp.flow(
    fp.map((group: DeviceGroup) => ({
      label: group.name,
      value: group.id,
    })),
  )(groups)

  const methods = useForm<ICreateDeviceForm>({
    mode: "all",
    defaultValues: {
      lessId: "",
      deviceName: "",
      deviceType: "",
      groupId: "",
      orgId: orgId as number,
    },
  })
  const [
    createDevice,
    { isLoading: createDeviceIsLoading, isSuccess: createDeviceIsSuccess },
  ] = useCreateDeviceMutation()
  const [sellDevice, { isLoading: sellDeviceIsLoading, isSuccess: sellDeviceIsSuccess }] =
    useSellDeviceMutation()
  const [
    activateDevice,
    { isLoading: activateDeviceIsLoading, isSuccess: activateDeviceIsSuccess },
  ] = useActivateDeviceMutation()
  const [deleteDevice, { isLoading: deleteDeviceIsLoading }] = useDeleteDeviceMutation()
  const isLoading =
    createDeviceIsLoading ||
    sellDeviceIsLoading ||
    activateDeviceIsLoading ||
    deleteDeviceIsLoading
  const isSuccess =
    createDeviceIsSuccess && sellDeviceIsSuccess && activateDeviceIsSuccess

  const createSellAndActivateDevice = async (data: ICreateDeviceForm): Promise<null> => {
    const create = (): Promise<null> =>
      createDevice({
        orgId: orgId as number,
        lessId: Number(data.lessId),
        deviceName: data.deviceName,
        deviceType: data.deviceType,
        settings: {
          backend: NEW_DEVICE_BACKEND,
        },
      }).unwrap()

    const sell = (): Promise<SellDeviceRes> =>
      sellDevice({
        lessId: Number(data.lessId),
      }).unwrap()

    const activate = (sellDeviceData: SellDeviceRes): Promise<null> =>
      activateDevice({
        orgId: orgId as number,
        lessId: Number(data.lessId),
        qrId: sellDeviceData.qr_id,
        groupId: Number(data.groupId),
        deviceName: data.deviceName,
      }).unwrap()

    const _delete = (): Promise<null> =>
      deleteDevice({ lessId: Number(data.lessId) }).unwrap()

    return create().then(() =>
      sell()
        .then(activate)
        .catch((error) => _delete().then(() => Promise.reject(error))),
    )
  }

  const onSubmit = async (data: ICreateDeviceForm) => {
    snackbarMutation({
      mutation: createSellAndActivateDevice(data),
      getSuccessMessage: () => t("device.CREATED_SUCCESSFULLY"),
      getErrorMessage: (error) => {
        const errorText = t("error.CREATING_ITEM", {
          item: t("generic.DEVICE").toLowerCase(),
        })
        const message = fp.get("data.message", error) || t("error.NETWORK_ERROR")
        return `${errorText}: ${addEndPeriodIfMissing(message)}`
      },
    })
      .then(onClose)
      .catch()
  }

  return (
    <>
      <Dialog
        open={open}
        scroll={"paper"}
        onClose={onClose}
        sx={{ "& .MuiPaper-root": { minWidth: "340px" } }}
      >
        <DialogTitle>{t("device.CREATE_DEVICE")}</DialogTitle>
        {!isSuccess && (
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <DialogContent
                dividers={true}
                sx={{
                  "& .MuiInput-root": {
                    fontSize: "1rem",
                  },
                }}
              >
                <ControlledInput
                  name="lessId"
                  placeholder={t("device.DEVICE_ID")}
                  label={t("device.DEVICE_ID")}
                  rules={{ required: true }}
                  variant="outlined"
                  disabled={isLoading}
                />
                <ControlledInput
                  name="deviceName"
                  placeholder={t("enterprise.DEVICE_NAME")}
                  label={t("enterprise.DEVICE_NAME")}
                  rules={{ required: true }}
                  variant="outlined"
                  disabled={isLoading}
                />
                <ControlledInput
                  name="deviceType"
                  type="select"
                  selectItems={deviceTypesOptions}
                  placeholder={t("enterprise.DEVICE_TYPE")}
                  label={t("enterprise.DEVICE_TYPE")}
                  rules={{ required: true }}
                  variant="outlined"
                  disabled={isLoading}
                />
                <ControlledInput
                  name="groupId"
                  type="select"
                  selectItems={deviceGroupsOptions}
                  placeholder={t("device_groups.GROUP_NAME")}
                  label={t("device_groups.GROUP_NAME")}
                  rules={{ required: true }}
                  variant="outlined"
                  disabled={isLoading}
                />
              </DialogContent>
              <DialogActions sx={{ justifyContent: "flex-end" }}>
                <Button
                  onClick={onClose}
                  sx={{
                    color: (theme: Theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.grey[300]
                        : theme.palette.grey[600],
                  }}
                  disabled={isLoading}
                >
                  {t("generic.CANCEL")}
                </Button>
                <LoadingButton sx={{ width: "fit-content" }} loading={isLoading}>
                  {t("generic.CREATE")}
                </LoadingButton>
              </DialogActions>
            </FormProvider>
          </form>
        )}
      </Dialog>
    </>
  )
}

export default CreateDevicePopup
