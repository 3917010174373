import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import fp from "lodash/fp"

import { useOrgsQuery } from "features/api"
import { selectCurrentUser, selectCurrentUserGlobalRole } from "features/store/authSlice"
import {
  selectCurrentOrgId,
  selectCurrentOrgUserRole,
  setOrg,
  setOrgUserRole,
} from "features/store/orgSlice"
import { ORGS_TIME, USER_ROLES } from "helpers/utils/constants"

const useOrgs = () => {
  const dispatch = useDispatch()
  const { orgId: urlOrgId } = useParams()
  const [orgsInfoLoading, setOrgsInfoLoading] = useState(false)
  const currentOrgId = useSelector(selectCurrentOrgId)
  const currentUser = useSelector(selectCurrentUser)

  const currentOrgUserRole = useSelector(selectCurrentOrgUserRole)
  const userGlobalRole = useSelector(selectCurrentUserGlobalRole)
  const defaultUserRole =
    userGlobalRole === "backoffice_admin" || userGlobalRole === "reseller"
      ? USER_ROLES.admin
      : currentUser?.includes("viewer")
      ? USER_ROLES.viewer
      : USER_ROLES.editor
  const {
    data,
    isFetching: orgsFetching,
    isLoading: orgsLoading,
    isError,
  } = useOrgsQuery(
    {
      user: String(currentUser),
    },
    {
      skip: !currentUser,
      pollingInterval: ORGS_TIME,
    },
  )

  const { orgs, roles } = useMemo(
    () => ({ orgs: data?.orgs || [], roles: data?.roles || [] }),
    [data?.orgs, data?.roles],
  )

  const getOrgById = useCallback(
    (orgId: number) => orgs.find(({ id }) => id === orgId),
    [orgs],
  )

  const urlOrgInUserOrgs = useMemo(
    () => getOrgById(Number(urlOrgId)),
    [getOrgById, urlOrgId],
  )

  const rolesByOrg = useMemo(() => fp.keyBy((role) => role.org_id, roles), [roles])

  const orgUserRole = useMemo(
    () => rolesByOrg[Number(currentOrgId)]?.role ?? defaultUserRole,
    [currentOrgId, rolesByOrg, defaultUserRole],
  )

  useEffect(
    () => {
      const dispatchOrgInfo = async () => {
        if (orgsFetching) {
          // We are still loading the orgs
        } else if (urlOrgInUserOrgs && String(currentOrgId) !== urlOrgId) {
          dispatch(
            setOrg({
              id: urlOrgInUserOrgs.id,
              userRole: rolesByOrg[urlOrgInUserOrgs.id]?.role ?? defaultUserRole,
              name: urlOrgInUserOrgs.name,
            }),
          )
        } else if (getOrgById(Number(currentOrgId))) {
          // No need to switch orgs, the current one is a valid option
        } else if (orgs.length > 0) {
          const org = orgs[0]
          dispatch(
            setOrg({
              id: org.id,
              userRole: rolesByOrg[org.id]?.role ?? defaultUserRole,
              name: org.name,
            }),
          )
        } else {
          dispatch(
            setOrg({
              id: null,
              userRole: defaultUserRole,
              name: null,
            }),
          )
        }
      }
      const setOrgInfo = async () => {
        if (orgsFetching) {
          setOrgsInfoLoading(true)
        }
        await dispatchOrgInfo()
        setOrgsInfoLoading(false)
      }
      setOrgInfo()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgs, orgsFetching, dispatch],
  )

  useEffect(() => {
    if (currentOrgUserRole !== orgUserRole) {
      dispatch(setOrgUserRole(orgUserRole))
    }
  }, [currentOrgUserRole, orgUserRole, dispatch])

  const dispatchOrg = ({ id, name }: { id: number; name: string }) => {
    dispatch(setOrg({ id, userRole: rolesByOrg[id]?.role ?? defaultUserRole, name }))
  }

  return {
    orgs,
    orgsFetching,
    orgsLoading,
    orgsInfoLoading,
    isError,
    dispatchOrg,
    getOrgById,
  }
}

export default useOrgs
