import type { FC } from "react"
import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import {
  type AlertSettingType,
  type AlertSettingUpsertForm,
} from "../../../types/alerts.types"
import { selectCurrentOrgId } from "../../../features/store/orgSlice"
import { useAddDeviceAlertSettingMutation } from "../../../features/api"
import { formatMutationParams } from "../../../helpers/utils/alerts"
import AlertSettingForm from "./AlertSettingForm"

interface IAddAlertSettingsProps {
  types: AlertSettingType[]
  onClose: () => void
}

const AddAlertSetting: FC<IAddAlertSettingsProps> = ({ types, onClose }) => {
  const { id } = useParams()
  const orgId = useSelector(selectCurrentOrgId)
  const methods = useForm<AlertSettingUpsertForm>({
    mode: "all",
    defaultValues: {
      type: "",
    },
  })
  const { resetField, watch } = methods
  const selectedTypeName = watch("type")
  const selectedType = useMemo(
    () => types?.find((type) => type.name === selectedTypeName),
    [types, selectedTypeName],
  )
  const [addDeviceAlertSetting, { isLoading, isError, error, isSuccess }] =
    useAddDeviceAlertSettingMutation()

  useEffect(() => {
    selectedType?.params.map((param) =>
      resetField(param.id, {
        defaultValue: param.default !== null ? param.default : "",
      }),
    )
  }, [resetField, selectedType])

  const onSubmit = async (data: AlertSettingUpsertForm) => {
    const defaultEnabledData = {
      ...data,
      active: true,
    }
    const formattedData = formatMutationParams(defaultEnabledData, selectedType)
    addDeviceAlertSetting({
      less_id: Number(id),
      org_id: Number(orgId),
      ...formattedData,
    })
  }

  return (
    <AlertSettingForm
      isLoading={isLoading}
      title="alerts.NEW_ALERT_SETTING"
      onSubmit={methods.handleSubmit(onSubmit)}
      methods={methods}
      types={types}
      selectedTypeName={selectedTypeName}
      onClose={onClose}
      buttonText="generic.CREATE"
      errorText="alerts.FAILED_CREATE_ALERT"
      isError={isError}
      error={error}
      isSuccess={isSuccess}
    />
  )
}

export default AddAlertSetting
