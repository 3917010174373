import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import fp from "lodash/fp"
import type { FC } from "react"
import { Box } from "@mui/material"

import type { DeviceFeatureProps, DeviceMarker } from "types/geolocation.types"
import type { Device } from "types/device.types"

import { Popup } from "widgets/styled/map"
import useRoutes from "helpers/hooks/useRoutes"
import GroupsChips from "widgets/deviceGroups/GroupsChips"
import CustomLink from "widgets/common/CustomLink"
import { featureProperties } from "helpers/utils/map"
import { formatDecimalValues } from "helpers/utils/common"

interface DevicePopupProps {
  data: DeviceMarker
}

type DeviceEntity = Device | DeviceFeatureProps

const DevicePopup: FC<DevicePopupProps> = ({ data }) => {
  const { groups, device } = data
  const { baseOrgURL } = useRoutes()
  const { i18n, t } = useTranslation()
  const locale = i18n.resolvedLanguage

  return (
    <Popup>
      <Stack spacing={0}>
        {featureProperties.Device.map((property: string, i) => {
          const propertyValue = device[property as keyof DeviceEntity]
          if (!fp.isNil(propertyValue)) {
            const formattedProperty =
              typeof propertyValue === "number"
                ? formatDecimalValues(propertyValue, locale)
                : propertyValue
            return (
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                key={`DeviceProperty-${device.id}-${i}`}
              >
                <Typography fontWeight="600">
                  {t(`geo_info.${property.toUpperCase()}`)}
                </Typography>
                <Typography variant="body2">{formattedProperty}</Typography>
              </Stack>
            )
          }
        })}
        {groups?.length && (
          <Stack gap="0.5rem">
            <Typography fontWeight="600">{t("device_groups.GROUPS")}:</Typography>{" "}
            <GroupsChips groups={groups} />
          </Stack>
        )}
      </Stack>
      {device.less_id && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          margin="0.2rem 0"
        >
          <CustomLink href={`${baseOrgURL}/devices/${device.less_id}`} bold>
            {t("geo_info.GO_TO_DEVICE")}
          </CustomLink>
        </Box>
      )}
    </Popup>
  )
}

export default DevicePopup
