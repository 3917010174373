import type { FC } from "react"
import { useTheme } from "@mui/system"
import { ButtonBase, IconButton, Stack, Typography } from "@mui/material"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { useFieldArray, useFormContext } from "react-hook-form"

import { useTranslation } from "react-i18next"
import ControlledInput from "../common/ControlledInput"

interface EmailsFormProps {
  emails?: string[]
}

const EmailsForm: FC<EmailsFormProps> = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { register, control } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: "emails",
  })

  return (
    <Stack>
      <Stack sx={{ maxWidth: "400px", gap: 1 }}>
        {fields?.map((field, index) => {
          return (
            <Stack
              key={field.id}
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <ControlledInput
                type="email"
                placeholder=""
                variant="outlined"
                size="small"
                {...register(`emails.${index}.email`, {
                  required: t("generic.FIELD_REQUIRED"),
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: t("generic.FIELD_INVALID_EMAIL"),
                  },
                })}
                styles={{ marginBottom: 0 }}
              />
              <IconButton color="primary" onClick={() => remove(index)}>
                <DeleteOutlineIcon color="error" sx={{ fontSize: "medium" }} />
              </IconButton>
            </Stack>
          )
        })}
      </Stack>
      <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1 }}>
        <ButtonBase
          sx={{ display: "flex", gap: 1 }}
          onClick={() => {
            append({
              email: "",
            })
          }}
        >
          <AddCircleOutlineIcon color="primary" fontSize="small" />
          <Typography sx={{ color: theme.palette.primary.main }}>
            {t("generic.ADD")}
          </Typography>
        </ButtonBase>
      </Stack>
    </Stack>
  )
}

export default EmailsForm
