import { useSelector } from "react-redux"

import { selectCurrentOrgId } from "features/store/orgSlice"
import { usePowerlinesQuery } from "features/api"

interface usePowerlinesProps {
  limit?: number
  cursor?: string
}

const usePowerlines = ({ limit, cursor }: usePowerlinesProps = {}) => {
  const orgId = useSelector(selectCurrentOrgId)

  const {
    data: powerlines,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = usePowerlinesQuery(
    {
      params: { org_id: Number(orgId), limit, cursor },
    },
    {
      skip: !orgId,
    },
  )

  return {
    powerlines,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export default usePowerlines
