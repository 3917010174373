import type { FC } from "react"
import { useTranslation } from "react-i18next"
import type { Theme } from "@mui/material"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

import PopUp from "./PopUp"
import Spinner from "./Spinner"

interface IConfirmationPopupProps {
  onClose: () => void
  open: boolean
  description?: string | string[]
  title?: string
  onConfirm: () => void
  mainButtonText: string
  isLoading: boolean
  isDeletePopup?: boolean
}

const ConfirmationPopup: FC<IConfirmationPopupProps> = ({
  onClose,
  open,
  description,
  title,
  onConfirm,
  mainButtonText,
  isLoading,
  isDeletePopup = false,
}) => {
  const { t } = useTranslation()

  return (
    <PopUp open={open} onClose={onClose} disableOverflow>
      {isLoading ? (
        <Box
          sx={{
            minWidth: "250px",
            minHeight: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          <DialogContent sx={{ justifyContent: "center", textAlign: "center" }}>
            {title && (
              <Typography variant="h5" component="h4" sx={{ mb: "1rem" }}>
                {title}
              </Typography>
            )}
            {description &&
              (Array.isArray(description) ? (
                description.map((line, i) => (
                  <Typography variant="body1" component="p" key={line + i}>
                    {line}
                  </Typography>
                ))
              ) : (
                <Typography variant="body1" component="p">
                  {description}
                </Typography>
              ))}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              sx={{
                color: (theme: Theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.grey[300]
                    : theme.palette.grey[600],
              }}
            >
              {t("generic.CANCEL")}
            </Button>
            <Button
              onClick={onConfirm}
              color={isDeletePopup ? "error" : "primary"}
              sx={{
                width: "fit-content",
              }}
            >
              {mainButtonText}
            </Button>
          </DialogActions>
        </>
      )}
    </PopUp>
  )
}

export default ConfirmationPopup
