import type { ReactElement, FC } from "react"

import { Marker } from "react-leaflet"

import type { DivIcon, Icon, LatLngTuple } from "leaflet"

import { MarkerTooltip } from "widgets/styled/map"

interface IMapMarkerProps {
  icon: Icon | DivIcon
  position: LatLngTuple | undefined
  tooltipContent?: ReactElement | string | null
  popup?: ReactElement
  zIndexOffset?: number
  riseOffset?: number
}

const MapMarker: FC<IMapMarkerProps> = ({
  popup,
  icon,
  position,
  zIndexOffset,
  tooltipContent,
  riseOffset = 1000,
}) => {
  return (
    <>
      {position && (
        <Marker
          riseOnHover
          position={position}
          icon={icon}
          zIndexOffset={zIndexOffset}
          riseOffset={riseOffset}
          autoPanOnFocus
        >
          {popup}
          {tooltipContent && (
            <MarkerTooltip direction="bottom" offset={[0, 15]} opacity={1}>
              {tooltipContent}
            </MarkerTooltip>
          )}
        </Marker>
      )}
    </>
  )
}

export default MapMarker
